const brainScanMessages = {
    intake_outtake_init: {
        title: "Searching Memory",
        body: "Making a decision on which path to take in order to provide an appropriate answer based on the query {{user_query}}"
    },
    intake_flow_init: {
        title: "Searching Memory",
        body: "Decided to go with the {{flow}} flow"
    },
    intake_outtake_flow_completion: {
        title: "Searching Memory",
        body: "Successfully generated an answer for the query {{user_query}}"
    },
    intake_outtake_flow_collab_started: {
        title: "Perusing",
        body: "Collaboration Flow initialized"
    },
    collaboration_app_not_found: {
        title: "Searching Memory",
        body: "No Suitable Collaboration App found to handle the query \"{{user_query}}\""
    }
};

export default brainScanMessages;
