import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type UploadFileMeta } from "../../models/upload.model";
import { removeDuplicates } from "../../utils/strings.utils";
import { type MemoriesTreeModel } from "../../models/memories.model";

export interface UploadDataItem {
    sector: string;
    location: string;
    path: string;
    file?: UploadFileMeta;
}

interface Namespace {
    email?: string;
    filename?: string;
    headerNames?: string;
    namespace?: string;
    id?: number;
    is_structured?: boolean;
    sector?: string;
    location?: string;
    relation?: string;
}

export interface UploadDataState {
    loading: boolean;
    isError: boolean;
    namespace: Namespace[];
    sectors: string[];
    locations: string[];
    items: UploadDataItem[];
    memoriesTree: MemoriesTreeModel[];
}

const initialState: UploadDataState = {
    loading: true,
    isError: false,
    namespace: [],
    items: [],
    sectors: [],
    locations: [],
    memoriesTree: []
};

const uploadDataSlice = createSlice({
    name: "uploadedData",
    initialState,
    reducers: {
        addSector (state, action: PayloadAction<string>) {
            // remove duplicates
            state.sectors = removeDuplicates([
                ...state.sectors,
                action.payload
            ]);
        },
        setSectors (state, action) {
            state.sectors = [...action.payload];
        },
        setNameSpaceLoading (state, action) {
            state.loading = action.payload;
        },
        setNameSpaceIsError (state, action) {
            state.isError = action.payload;
        },
        addLocation (
            state,
            action: PayloadAction<{ sector: string; location: string }>
        ) {
            // set state
            state.locations = removeDuplicates([
                ...state.locations,
                action.payload.location
            ]);
        },
        setLocations (state, action) {
            state.locations = [...action.payload];
        },
        setNamespace (state, action) {
            state.namespace = action.payload;
        },
        addUploadedItem (state, action) {
            state.items = [...state.items, action.payload];
        },
        setUploadedItems (state, action) {
            state.items = [...action.payload];
        },
        setNameSpaceLoadingState (state, action) {
            state.loading = action.payload;
        },
        setMemoriesTree (state, action) {
            state.memoriesTree = action.payload;
        },
        addMemoryToTree (
            state,
            action: PayloadAction<{
                sector: string;
                location: string;
                file: string;
            }>
        ) {
            const { sector, location, file } = action.payload;
            // Check if sector exist in tree
            const sectorIndex = state.memoriesTree.findIndex(
                (memory) => memory.sector === sector
            );
            if (sectorIndex > -1) {
                state.memoriesTree = state.memoriesTree.map((tree) => {
                    // now we check if this location exist
                    const locationIndex = tree.locations.findIndex(
                        (loc) => loc.location === location
                    );
                    if (locationIndex > -1) {
                        tree.locations[locationIndex].files.push(file);
                    } else {
                        tree.locations.push({
                            location_id: location,
                            location,
                            location_display: location,
                            files: [file]
                        });
                    }
                    return tree;
                });
            } else {
                state.memoriesTree = [
                    ...state.memoriesTree,
                    {
                        sector,
                        sector_display: sector,
                        sector_id: sector,
                        locations: [
                            {
                                location_id: location,
                                location,
                                location_display: location,
                                files: [file]
                            }
                        ]
                    }
                ];
            }
        },
        updateMemorySectorName (
            state,
            action: PayloadAction<{ id: string; name: string }>
        ) {
            state.memoriesTree = state.memoriesTree.map((tree) => {
                if (tree.sector_id === action.payload.id) {
                    tree.sector_display = action.payload.name;
                }
                return tree;
            });
        },
        updateMemoryLocationName (
            state,
            action: PayloadAction<{
                id: string;
                sector_id: string;
                name: string;
            }>
        ) {
            state.memoriesTree = state.memoriesTree.map((tree) => {
                if (tree.sector_id === action.payload.sector_id) {
                    tree.locations.map((location) => {
                        if (location.location_id === action.payload.id) {
                            location.location_display = action.payload.name;
                        }
                        return location;
                    });
                }
                return tree;
            });
        },
        deleteMemorySector (state, action: PayloadAction<{ id: string }>) {
            state.memoriesTree = state.memoriesTree.filter(
                (tree) => tree.sector_id !== action.payload.id
            );
        },
        deleteMemoryLocation (
            state,
            action: PayloadAction<{ sectorId: string; locationId: string }>
        ) {
            state.memoriesTree = state.memoriesTree.map((tree) => {
                if (tree.sector_id === action.payload.sectorId) {
                    tree.locations = tree.locations.filter((location) => {
                        return (
                            location.location_id !== action.payload.locationId
                        );
                    });
                }
                return tree;
            });
        }
    }
});

export const {
    addUploadedItem,
    setUploadedItems,
    addSector,
    setNameSpaceIsError,
    setNameSpaceLoading,
    setMemoriesTree,
    setSectors,
    setNamespace,
    addLocation,
    addMemoryToTree,
    setNameSpaceLoadingState,
    updateMemoryLocationName,
    updateMemorySectorName,
    setLocations,
    deleteMemoryLocation,
    deleteMemorySector
} = uploadDataSlice.actions;

export default uploadDataSlice.reducer;
