import { useEffect } from "react";
import KeycloakClient from "../hooks/keycloak.hook";
import { useHttpClient } from "../utils/http.utils";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState, setAuthState, setAuthUser } from "../store/slices/auth.slice";
import { type UserProfile } from "../models/user.profile";

interface KeycloakWrapperProps {
    children: React.ReactNode;
}

const KeycloakWrapper: React.FC<KeycloakWrapperProps> = ({ children }) => {
    const authenticated = useSelector(selectAuthState);
    const { fetchUserCredentialsApi } = useHttpClient();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            KeycloakClient.getInstance().addSuccessCallback(() => {
                dispatch(setAuthState(true));
            });
            await KeycloakClient.getInstance().init();
        })();
    }, [dispatch]);

    useEffect(() => {
        if (authenticated) {
            (async () => {
                const authUser = await KeycloakClient.getInstance().getAuthProfile();

                await fetchUserCredentialsApi(authUser?.email);

                const authUserData: UserProfile = {
                    email: authUser?.email || "",
                    authUserId: authUser?.id || "",
                    isActive: true
                };

                if (authUserData.authUserId) {
                    dispatch(setAuthState(true));
                    dispatch(setAuthUser(authUserData));
                }
            })();
        }
    }, [authenticated, dispatch]);

    if (!authenticated) {
        return <></>;
    }

    return <>{children}</>;
};

export default KeycloakWrapper;
