import ProfileDropdown from "../components/ProfileDropdown";
import RoomMessageCard from "../components/RoomMessageCard";
import RoomMessageInput from "../components/RoomMessageInput";
import BrainActivity from "../components/waiting-room/BrainActivity";
import "../styles/WaitingRoom.component.scss";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ViewLayoutComponent } from "../components/ViewLayout.component";
import { LordIcon } from "../components/icons/LordIcon";
import { FiArrowLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
    type RoomsState,
    selectActiveRoomId,
    setActiveRoomFileList,
    setActiveRoomId,
    setWatchingCollabIdList
} from "../store/slices/rooms.slice";
// import { GroupsState } from "../store/slices/groups.slice";
import { useEffect, useRef, useState } from "react";
import {
    type FileObject,
    type RoomGroupModel,
    type RoomMessage
} from "../models/room.model";
import { useHttpClient } from "../utils/http.utils";
import {
    type RoomMessageThreadState,
    addThreadMessage,
    confirmThreadMessage,
    setThreadMessages,
    updateThreadMessageLoading,
    clearThreadMessages
} from "../store/slices/threads.slice";
import RoomVideoMessageCard from "../components/RoomVideoMessageCard";
import RoomAudioMessageCard from "../components/RoomAudioMessageCard";
import {
    type RoomMessageWithLoading,
    confirmMessage,
    setMessages
} from "../store/slices/message.slice";
import generateUUID from "../utils/collaborate/generateUUID";
import { type Socket } from "socket.io-client";
import toast from "react-hot-toast";
import {
    type BrainScanState,
    setBrainScanPersonas,
    updateBrainScanSequenceMessage
} from "../store/slices/brain-scan.slice";
import { initSocket } from "../utils/socket.utils";
import { getFileLink } from "../utils/collaborate/parser";
import { TemporaryRecords } from "../components/TemporaryRecords";
import InteractiveInput from "../components/interactiveInput";
import { type BrainScanSequenceMessage } from "../models/brain-scan.model";
import LoadingMessagesComponent from "../components/LoadingMessagesComponent";

const ThreadView = () => {
    const navigate = useNavigate();
    const threadMessagesState: RoomMessageThreadState = useSelector(
        (state: any) => state.threadMessages
    );
    // const messages: RoomMessageWithLoading[] = useSelector((state: any) => state.messages);
    const { id, messageId: parentMessageId } = useParams();
    const activeRoomId = useSelector(selectActiveRoomId);
    // First check if room type is a group
    // const isGroup = window.location.pathname.includes("/room-group/");
    const {
        listRoomMessageApi,
        createRoomMessageApi,
        addVideoToRoomMessageApi,
        addAudioToRoomMessageApi,
        addMessageToHistoryApi,
        listFileApi,
        sendExecutionCommandApi,
        executeToolWithInputsApi
    } = useHttpClient();
    const dispatch = useDispatch();
    const localIDsSent = useRef<Set<string>>(new Set());
    const socketRef = useRef<Socket | null>(null);

    const roomsState: RoomsState = useSelector((state: any) => state.rooms);
    // const groupsState: GroupsState = useSelector((state: any) => state.groups);
    const { data: loggedInUser } = useSelector((state: any) => state.account);

    const messagesRef = useRef<RoomMessageWithLoading[]>(
        threadMessagesState.data
    );
    messagesRef.current = threadMessagesState.data;
    // local state
    const [roomData, setRoomData] = useState<RoomGroupModel>();
    const [parentMessage, setParentMessage] =
        useState<RoomMessageWithLoading>();
    const [lastDecisionMessageId, setLastDecisionMessageId] =
        useState<string>("");
    const [isCollaborateLoading, setIsCollaborateLoading] =
        useState<boolean>(false);
    const [displayUserInputs, setDisplayUserInputs] = useState<boolean>(false);
    const [recentUserInteraction, setRecentUserInteraction] =
        useState<BrainScanSequenceMessage | null>();

    const brainScanState: BrainScanState = useSelector(
        (state: any) => state.brainScan
    );

    const messagesContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDisplayUserInputs(true);

        const message: BrainScanSequenceMessage =
            brainScanState.sequenceMessageList &&
            brainScanState.sequenceMessageList.length > 0
                ? brainScanState.sequenceMessageList[
                      brainScanState.sequenceMessageList.length - 1
                ]
                : null!;

        setRecentUserInteraction(message);
    }, [brainScanState.sequenceMessageList]);

    useEffect(() => {
        const fetchFileList = async () => {
            const fileList = await listFileApi({ room: activeRoomId });

            dispatch(setActiveRoomFileList(fileList));
        };

        if (activeRoomId) {
            fetchFileList();
        }
    }, [dispatch, activeRoomId]);

    useEffect(() => {
        setIsCollaborateLoading(false);
        if (
            threadMessagesState.data.at(-1)?.message
                ?.auto_interaction_message_type === "execute_next_cell" ||
            threadMessagesState.data.at(-1)?.message
                ?.auto_interaction_message_type === "execute_tool"
        ) {
            setLastDecisionMessageId(
                threadMessagesState.data.at(-1)?.message.id || ""
            );
        } else {
            setLastDecisionMessageId("");
        }
    }, [threadMessagesState.data]);

    useEffect(() => {
        // clear thread messages
        dispatch(clearThreadMessages());
        // Get room data from state
        // Then get the room data
        const room = roomsState.data?.find(data => data.id === id);
        // set data
        setRoomData(room);

        if (room) {
            // Set the active room
            dispatch(setActiveRoomId(room.id));

            // Set Brain Scan Personas
            if (room.persona_member_list) {
                dispatch(
                    setBrainScanPersonas(
                        room.persona_member_list.map(
                            personaMember => personaMember.persona
                        )
                    )
                );
            }

            // // set parent message from messages state
            // const parentMessage = messages.find((message) => message.message.id === parentMessageId);

            // Fetch messages from server
            const fetchMessages = async () => {
                const toastId = toast.loading("Loading messages...");

                const parentMessage: any = (
                    await listRoomMessageApi({
                        room: id!,
                        id: parentMessageId
                    })
                )?.[0];

                const fetchedMessages = await listRoomMessageApi({
                    room: id!,
                    parent_id: parentMessageId
                });

                const collabMessages: RoomMessage[] = await listRoomMessageApi({
                    room: id!,
                    collaboration_app: { "!=": null }
                });

                const messagesWithLoading: RoomMessageWithLoading[] =
                    fetchedMessages.map((message: RoomMessage) => ({
                        message,
                        loading: false,
                        localID: message.id!
                    }));

                dispatch(setThreadMessages(messagesWithLoading));
                dispatch(
                    setWatchingCollabIdList(
                        collabMessages.map(
                            message => message.collaboration_app!
                        )
                    )
                );

                // Handle parent Message
                if (parentMessage) {
                    const parentMessageProper: RoomMessageWithLoading = {
                        message: parentMessage,
                        loading: false,
                        localID: parentMessage.id as string
                    };
                    setParentMessage(parentMessageProper);
                    dispatch(setMessages([parentMessageProper]));
                }

                toast.dismiss(toastId);
            };

            fetchMessages();
        }
    }, [dispatch, roomsState.data]);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = initSocket();
        }

        socketRef.current.connect();

        if (socketRef.current) {
            socketRef.current.on(`room-message-creation-${id}`, data => {
                // make sure message is a threaded message
                if (data?.room_message?.parent_id !== parentMessageId) {
                    return;
                }
                // change nature of user id so it can be matched and showed on the right side
                const incomingMessage = { ...data.room_message };
                console.log(incomingMessage);

                // Check if the message ID is in the messages you have sent
                const existingMessageByID = messagesRef.current.find(
                    msg => msg.message.id === incomingMessage.id
                );

                // If it exists, it's an acknowledgment from the server
                if (existingMessageByID) {
                    dispatch(
                        updateThreadMessageLoading({
                            id: incomingMessage.id,
                            loading: false
                        })
                    );

                    if (localIDsSent.current.has(existingMessageByID.localID)) {
                        dispatch(
                            confirmThreadMessage({
                                localID: existingMessageByID.localID,
                                confirmedID: incomingMessage.id
                            })
                        );
                        localIDsSent.current.delete(
                            existingMessageByID.localID
                        ); // Remove the localID since it's now confirmed by the server
                    }
                } else {
                    const newMessage = {
                        message: {
                            ...incomingMessage,
                            user: {
                                id: data.room_message.user
                            }
                        },
                        loading: false,
                        localID: generateUUID() // Generate a new localID for this incoming message
                    };
                    dispatch(addThreadMessage(newMessage));
                }
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.removeAllListeners();
                socketRef.current.disconnect();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            const scrollPosition = scrollHeight - clientHeight;
            messagesContainerRef.current.scrollTo(0, scrollPosition);
        }
    }, [threadMessagesState.data]);

    const isGroup = () => roomData?.is_single !== "yes";

    const onVoiceRecorded = async (blob: Blob) => {
        try {
            const response = await addAudioToRoomMessageApi(blob);
            if (response) {
                await createRoomMessageApi({
                    room: id,
                    user: loggedInUser?.id,
                    message_type: "voice",
                    message_data: `${process.env.REACT_APP_API_URL}/api/v1/room-message/show-audio-media/${response?.data?.saved_filename}/${response?.data?.extension}`,
                    tagged_member_list: [],
                    type: "room_message",
                    parent_id: parentMessageId,
                    is_thread: "yes"
                });
            }
        } catch (error) {
            console.error("Failed to upload audio:", error);
        }
    };

    const onVideoRecorded = async (blob: Blob) => {
        try {
            const localID = generateUUID();
            localIDsSent.current.add(localID);

            const toastId = toast.loading("Uploading video, Please wait..", {
                duration: 9000000000000
            });
            // upload video to server,
            const response = await addVideoToRoomMessageApi(
                blob,
                "recorded-video.webm"
            );
            // upload to server
            if (response) {
                // Create a new message object
                const messageToSend: RoomMessageWithLoading = {
                    message: {
                        room: id,
                        user: loggedInUser?.id,
                        message_type: "video",
                        message_data: response?.data.video_id,
                        tagged_member_list: [],
                        // tagged_user_list: [],
                        type: "room_message",
                        parent_id: parentMessageId,
                        is_thread: "yes"
                    },
                    loading: true,
                    localID
                };
                await createRoomMessageApi(messageToSend);
            }

            // remove loading
            toast.dismiss(toastId);
        } catch (error) {
            console.error("Failed to upload video:", error);
        }
    };

    const onText = async ({ message, taggedMemberList }) => {
        const localID = generateUUID();
        localIDsSent.current.add(localID);
        // Create a new message object
        const messageToSend: RoomMessageWithLoading = {
            message: {
                room: id,
                user: loggedInUser?.id,
                message_type: "text",
                message_data: message,
                tagged_member_list:
                    roomData?.is_single === "yes"
                        ? [roomData.persona_member_list[0].id]
                        : taggedMemberList,
                record_url: "",
                record_file: "",
                type: "room_message",
                parent_id: parentMessageId,
                is_thread: "yes"
            },
            loading: true,
            localID
        };
        // dispatch(addMessage(messageToSend));
        try {
            console.log("Sending message:", messageToSend.message);
            const response = await createRoomMessageApi(messageToSend.message);

            const confirmedID = response.room_message.id;
            if (confirmedID) {
                dispatch(
                    confirmMessage({
                        localID,
                        confirmedID
                    })
                );
            } else {
                console.error(
                    "Error: No confirmed ID received from the server."
                );
            }
        } catch (error) {
            console.error("Error sending the text message:", error);
        }
    };

    const ratePersonaResponse = async (message: RoomMessage) => {
        const data = await addMessageToHistoryApi({
            message: message.message_data,
            messageId: message.id,
            userId: loggedInUser.id,
            personaId: loggedInUser.id // TODO: CHANGE TO PERSONA ID, THIS IS DONE FOR TESTING PURPOSES
        });

        if (data === false) {
            // Send a status to the message component that the message has been rated
            return false;
        } else {
            // Send a status to the message component that the message has not been rated
            return true;
        }
    };

    async function onUserInputSubmit(responses) {
        try {
            // hide input control
            if (recentUserInteraction) {
                dispatch(
                    updateBrainScanSequenceMessage({
                        ...recentUserInteraction,
                        user_input_submitted: true
                    })
                );
            }

            const res = await executeToolWithInputsApi(responses);
            if (res.success) {
                setDisplayUserInputs(false);
                // setRecentUserInteraction(null);
            } else {
                // show input control
                if (recentUserInteraction) {
                    dispatch(
                        updateBrainScanSequenceMessage({
                            ...recentUserInteraction,
                            user_input_submitted: false
                        })
                    );
                }
                toast.error("Error Uploading file. Please try again.");
            }
        } catch (error) {
            // show input control
            if (recentUserInteraction) {
                dispatch(
                    updateBrainScanSequenceMessage({
                        ...recentUserInteraction,
                        user_input_submitted: false
                    })
                );
            }
            toast.error("Error Uploading file. Please try again.");
        }
    }

    const handleCollabDecision = async (
        decisionMessageType: RoomMessage["auto_interaction_message_type"],
        decision: "yes" | "no",
        collaborationAppId: string
    ) => {
        if (collaborationAppId) {
            let responseType:
            | "execute_next_cell"
            | "execute_previous_cell"
            | "execute_tool"
            | "cancel_execute_tool"
            | "" = "";

            // if (
            //     collaborate_state.viewMode === "initialize" &&
            //     collaborate_state.selectedCollaborationApp?.id
            // ) {

            if (decision === "yes") {
                switch (decisionMessageType) {
                    case "execute_next_cell":
                        responseType = "execute_next_cell";
                        break;
                    case "execute_tool":
                        responseType = "execute_tool";
                        break;
                    default:
                }
            } else if (decision === "no") {
                switch (decisionMessageType) {
                    case "execute_next_cell":
                        responseType = "execute_previous_cell";
                        break;
                    case "execute_tool":
                        responseType = "cancel_execute_tool";
                        break;
                    default:
                }
            }

            if (responseType !== "") {
                sendExecutionCommandApi(collaborationAppId, responseType);

                // Clear last decision message id
                setLastDecisionMessageId("");

                // Show loading spinner
                setIsCollaborateLoading(true);
            }

            // }
        }
    };

    const generateCollabHandler = (collaborationAppId: string) => {
        return async (
            decisionMessageType: RoomMessage["auto_interaction_message_type"],
            decision: "yes" | "no"
        ) =>
            await handleCollabDecision(
                decisionMessageType,
                decision,
                collaborationAppId
            );
    };

    return (
        <ViewLayoutComponent>
            <div className="waiting-room thread-view">
                <div className="grid-column bg-white">
                    <ProfileDropdown dropDownStyle={{ boxShadow: "none" }} />
                    {/* <WaitingLeftPane /> */}
                    <div className="pt-[20px] temporary-record-holder">
                        <TemporaryRecords />
                    </div>
                </div>
                <div className="main-grid grid-column h-full">
                    <div className="main-grid-wrapper">
                        <div className="waiting-room-header-box">
                            <div className="leading-box">
                                <button
                                    className="button"
                                    onClick={() => navigate(-1)}
                                >
                                    <FiArrowLeft
                                        className="text-gray-600"
                                        size="22px"
                                    />
                                </button>

                                <div className="header-box">
                                    <h2 className="title">
                                        <p
                                            className="w-full md:w-fit md:m-w-[200px] overflow-hidden truncate mb-1 md:mb-0"
                                            title={roomData?.title}
                                        >
                                            {roomData?.title}
                                        </p>
                                        <p className="title-description w-full truncate">
                                            {roomData?.description}
                                        </p>
                                    </h2>
                                    {/* <p className="subtitle">
                                        Montague Adameve
                                        <span className="arin-text">arin</span>, Paul Snow, James Duchenne & 235 others.
                                    </p> */}
                                    <p className="subtitle">Thread</p>
                                </div>
                            </div>
                            <div className="line w-full">
                                <div className="line-inner"></div>
                            </div>
                            <div className="actions-box">
                                <NavLink to={"/"}>
                                    <button className="button">
                                        <LordIcon
                                            src="/lord-icons/stack.json"
                                            trigger="hover"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={30}
                                        />
                                    </button>
                                </NavLink>
                            </div>
                        </div>

                        {/* <div className="waiting-room-header-content lg-view">
                        <div className="flex items-center gap-2">
                            <NavLink to={ROUTES.WAITING_ROOM}>
                                <button className="back-button">
                                    <BsArrowLeft className="icon" />
                                </button>
                            </NavLink>
                            <NavLink to={ROUTES.ROOT}>
                                <button className="back-button">
                                    <RiStackLine className="icon" />
                                </button>
                            </NavLink>
                        </div>
                        <div className="divider"></div>
                        <p className="">Thread</p>
                    </div> */}
                        <div
                            className="waiting-room-body-content py-8 pr-12"
                            ref={messagesContainerRef}
                        >
                            {parentMessage?.message.message_type === "text" && (
                                <RoomMessageCard
                                    hideThread={true}
                                    isInGroup={isGroup()}
                                    isOwnMessage={
                                        parentMessage?.message?.user?.id ===
                                        loggedInUser?.id
                                    }
                                    text={[parentMessage.message.message_data!]}
                                    otherProps={parentMessage}
                                    auto_interaction_message_type={
                                        parentMessage.message
                                            .auto_interaction_message_type
                                    }
                                    onSendExecutionCommand={
                                        parentMessage.message.collaboration_app
                                            ? generateCollabHandler(
                                                  parentMessage.message
                                                      .collaboration_app
                                            )
                                            : undefined
                                    }
                                    lastDecisionMessageId={
                                        lastDecisionMessageId
                                    }
                                />
                            )}
                            {parentMessage?.message.message_type ===
                                "voice" && (
                                <RoomAudioMessageCard
                                    isOwnMessage={
                                        parentMessage?.message?.user?.id ===
                                        loggedInUser?.id
                                    }
                                    hideThread={true}
                                    audioSrc={
                                        parentMessage.message.message_data!
                                    }
                                    loading={false}
                                    isInGroup={isGroup()}
                                    otherProps={parentMessage}
                                    onRateResponse={async () =>
                                        await ratePersonaResponse(
                                            parentMessage?.message
                                        )
                                    }
                                />
                            )}
                            {parentMessage?.message.message_type ===
                                "video" && (
                                <RoomVideoMessageCard
                                    videoId={Number(
                                        parentMessage.message.message_data!
                                    )}
                                    hideThread={true}
                                    isOwnMessage={
                                        parentMessage?.message?.user?.id ===
                                        loggedInUser?.id
                                    }
                                    loading={false}
                                    isInGroup={isGroup()}
                                    otherProps={parentMessage}
                                    onRateResponse={async () =>
                                        await ratePersonaResponse(
                                            parentMessage?.message
                                        )
                                    }
                                />
                            )}

                            {roomsState.isLoadingMessages && (
                                <LoadingMessagesComponent />
                            )}

                            {threadMessagesState.data.map((_, index, arr) => {
                                const item = arr[arr.length - 1 - index];
                                const message = item.message;

                                const isOwnMessage =
                                    message?.user?.id === loggedInUser?.id;

                                if (message.message_type === "text") {
                                    return (
                                        <div key={message.id}>
                                            {message.message_data && (
                                                <RoomMessageCard
                                                    messageId={message.id}
                                                    questionId={
                                                        message.question_id
                                                    }
                                                    roomId={id}
                                                    text={[
                                                        message.message_data
                                                    ]}
                                                    isInGroup={isGroup()}
                                                    isOwnMessage={isOwnMessage}
                                                    fileObject={
                                                        (
                                                            message.file_object as FileObject
                                                        )?.id
                                                            ? {
                                                                name: (
                                                                    message.file_object as FileObject
                                                                ).name,
                                                                link: getFileLink(
                                                                      message.file_object as FileObject
                                                                )
                                                            }
                                                            : undefined
                                                    }
                                                    loading={item.loading}
                                                    hideThread={true}
                                                    otherProps={message}
                                                    onRateResponse={async () =>
                                                        await ratePersonaResponse(
                                                            message
                                                        )
                                                    }
                                                    auto_interaction_message_type={
                                                        message.auto_interaction_message_type
                                                    }
                                                    onSendExecutionCommand={
                                                        parentMessage?.message
                                                            .collaboration_app
                                                            ? generateCollabHandler(
                                                                  parentMessage
                                                                      ?.message
                                                                      .collaboration_app
                                                            )
                                                            : undefined
                                                    }
                                                    lastDecisionMessageId={
                                                        lastDecisionMessageId
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                } else if (message.message_type === "video") {
                                    return (
                                        <div key={message.id}>
                                            <RoomVideoMessageCard
                                                videoId={Number(
                                                    message.message_data
                                                )}
                                                hideThread={true}
                                                isOwnMessage={isOwnMessage}
                                                loading={item.loading}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else if (message.message_type === "voice") {
                                    return (
                                        <div key={message.id}>
                                            <RoomAudioMessageCard
                                                isOwnMessage={isOwnMessage}
                                                hideThread={true}
                                                audioSrc={message.message_data!}
                                                loading={item.loading}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={message.id}>
                                            <></>
                                        </div>
                                    );
                                }
                            })}
                            <RoomMessageInput
                                room={roomData}
                                showActions={["write", "voice", "video"]}
                                onVoice={onVoiceRecorded}
                                onVideo={onVideoRecorded}
                                onText={onText}
                                isTextDisabled={isCollaborateLoading}
                                disableTagging={roomData?.is_single === "yes"}
                            />

                            {displayUserInputs &&
                                recentUserInteraction?.message_type === "ai" &&
                                recentUserInteraction?.message_format ===
                                    "input" &&
                                !recentUserInteraction?.user_input_submitted && (
                                <div className="ai-message">
                                    <div className="character">
                                        <p className="character-message">
                                            <InteractiveInput
                                                key={
                                                    recentUserInteraction.collaboration_app_id
                                                }
                                                collaborationAppId={
                                                    recentUserInteraction.collaboration_app_id!
                                                }
                                                message={
                                                    recentUserInteraction
                                                }
                                                onUserInputSubmit={
                                                    onUserInputSubmit
                                                }
                                            />
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid-column activity-grid bg-white">
                    <BrainActivity />
                </div>
            </div>
        </ViewLayoutComponent>
    );
};

export default ThreadView;
