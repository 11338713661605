export function formatFileSize (bytes: number, decimalPoint: number) {
    if (bytes === 0) {
        return "0 Bytes";
    }
    const k = 1000;
    const dm = decimalPoint || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatCurrency (value: number) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });

    return formatter.format(value);
}

export function formatReadableNumber (value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
