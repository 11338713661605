import { type UserProfile } from "../../models/user.profile";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
    isLoggedIn: boolean;
    user: UserProfile | null;
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState (state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setAuthUser (state, action: PayloadAction<UserProfile>) {
            state.user = action.payload;
        },
        updateAuthUserProfile (state, action: PayloadAction<any>) {
            const payloadData = {
                ...state.user,
                ...action.payload,
                ...(action.payload.name && { fullname: action.payload.name })
            };
            delete payloadData.name;
            state.user = payloadData;
            // remove unnecessary fields
            // update cookies
            // updateUserSession(payloadData as UserProfile);
        }
    }
});

export const { setAuthState, setAuthUser, updateAuthUserProfile } = authSlice.actions;

export const selectAuthState = (state: { auth: AuthState }) => state.auth.isLoggedIn;

export default authSlice.reducer;
