import { useRef } from "react";
import "../../styles/GenealogyDetailsPopup.component.scss";
import Draggable from "react-draggable";

export interface genealogyDetailProps {
    name: string;
    creator: string;
    dateOfCreation: string;
    dateOfDeath?: string;
    parents: string[];
    handleClosePopup: () => void;
}

export default function GenealogyDetailsPopup ({ name, creator, dateOfCreation, parents, dateOfDeath, handleClosePopup }: genealogyDetailProps) {
    const nodeRef = useRef<any>(null);

    return (
        <Draggable ref={nodeRef} defaultClassName='genealogyDetails-container'>
            <div ref={nodeRef} className="">
                <button onClick={handleClosePopup} className="closeIcon-container">
                    &times;
                </button>
                <div className="genealogyDetails-container__item">
                    <span>Name :</span>
                    <span>{name}</span>
                </div>
                {creator && <div className="genealogyDetails-container__item">
                    <span>Creator :</span>
                    <span>{creator}</span>
                </div>}
                {dateOfCreation && <div className="genealogyDetails-container__item">
                    <span>Date of Creation :</span>
                    <span>{dateOfCreation}</span>
                </div>}
                {dateOfDeath && <div className="genealogyDetails-container__item">
                    <span>Date of Death :</span>
                    <span>{dateOfDeath}</span>
                </div>}
                {parents.length > 0 && <div className="genealogyDetails-container__item">
                    <span>Parents:</span>
                    <div>
                        {
                            parents.map((item: any, index: number) =>
                                <p key={index}>{item?.username || item?.email}</p>
                            )
                        }
                    </div>
                </div>}
            </div>
        </Draggable>

    );
}
