import {
    Button,
    FormControl,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useHttpClient } from "../../utils/http.utils";
import { useSelector } from "react-redux";
import { type PaymentMethodModel } from "../../models/payment.model";
import hotToast from "react-hot-toast";
import { type PaymentIntent, type Stripe } from "@stripe/stripe-js";
import { showPaymentIntentStatusMessage } from "../../utils/stripe";

interface CreditTopUpFormProps {
    isOpen: boolean;
    stripe: Stripe;
    onClose: () => void;
}
export default function CreditTopUpForm (props: CreditTopUpFormProps) {
    const purchaseModal = useDisclosure();
    const { purchaseCreditsApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([]);

    const [isSaving, setIsSaving] = useState(false);
    const stripe = props.stripe;

    useEffect(() => {
        if (props.isOpen) {
            purchaseModal.onOpen();
        } else {
            purchaseModal.onClose();
        }
        // if payment methods
        if (paymentMethods.length > 0) {
            setPaymentMethodOptions(
                paymentMethods.map((pm) => ({
                    value: pm.stripe_id,
                    label: `**** **** **** ${pm.card_extract}`,
                    cardBrand: pm.card_brand,
                    expiryDate: pm.expiry_date
                }))
            );
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        purchaseModal.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    /**
     * Form validators
     */
    function validateRequired (key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const purchaseCredits = async (values) => {
        try {
            setIsSaving(true);
            const { paymentIntent }: { paymentIntent: PaymentIntent } = await purchaseCreditsApi({
                amount: parseFloat(values.amount as string),
                payment_method: values.payment_method,
                return_url: `${process.env.REACT_APP_URL}/payments/callback/credit-purchase`
            });

            /**
             * The data return from the server is the stripe payment intent object,
             *  @see https://stripe.com/docs/api/payment_intents/object
             *
             * We need to do two things, if the next_action, is returned we need to handle that with stripe
             * Check the status of the payment intent
             */
            if (paymentIntent.next_action) {
                // handle next actions
                const { error, paymentIntent: paymentIntentNew } = await stripe.handleCardAction(paymentIntent.client_secret!);
                // If error ocurred, tell user payment could not be completed
                if (error) {
                    hotToast.error(error.message || "We are unable to complete your payment at this time");
                } else {
                    hotToast(showPaymentIntentStatusMessage(paymentIntentNew.status));
                }
            } else {
                hotToast(showPaymentIntentStatusMessage(paymentIntent.status));
                // If success close modal
                console.log(paymentIntent.status);
                if (paymentIntent.status === "succeeded") {
                    hotToast.success("Your payment was successful");
                }
            }
            closeFormModal();
            setIsSaving(false);
        } catch (e) {
            setIsSaving(false);
            console.error(e); // debug
            hotToast.error("Cannot purchase credits at this time, Please try again later");
        }
    };

    return (
        <Modal
            isOpen={purchaseModal.isOpen}
            onClose={closeFormModal}
            closeOnOverlayClick={!isSaving}
            size="md"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        amount: 0,
                        payment_method: ""
                    }}
                    onSubmit={purchaseCredits}
                >
                    {() => (
                        <Form>
                            <ModalHeader>Add Credits</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="mt-[32px]">
                                    <Field name="amount" validate={(e) => validateRequired("Amount", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={6} isInvalid={form.errors.amount && form.touched.amount}>
                                                <p className="text-[13px] font-medium mb-1">Amount</p>

                                                <NumberInput min={1}>
                                                    <NumberInputField
                                                        prefix="$"
                                                        {...field}
                                                        placeholder="Amount (USD)"
                                                    />
                                                </NumberInput>
                                                <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field
                                        name="payment_method"
                                        validate={(e) => validateRequired("Payment Method", e)}
                                    >
                                        {({ field, form }) => (
                                            <FormControl
                                                mb={3}
                                                isInvalid={form.errors.payment_method && form.touched.payment_method}
                                            >
                                                <p className="text-[13px] font-medium mb-1">Payment Method</p>
                                                <Select
                                                    placeholder="Select payment method"
                                                    value={paymentMethodOptions.find((option) =>
                                                        field.value ? field.value.includes(option.value) : ""
                                                    )}
                                                    isSearchable={false}
                                                    onChange={(selectedOption) => {
                                                        form.setFieldValue(
                                                            "payment_method",
                                                            (selectedOption as any)?.value
                                                        );
                                                    }}
                                                    onBlur={field.onBlur}
                                                    formatOptionLabel={PaymentMethodOptionLabel}
                                                    options={paymentMethodOptions}
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                />
                                                <FormErrorMessage>{form.errors.payment_method}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <Button variant="ghost" mr={3} onClick={closeFormModal}>
                                    Close
                                </Button>
                                <Button
                                    isLoading={isSaving}
                                    loadingText="Purchasing.."
                                    type="submit"
                                    color="white"
                                    colorScheme="brand"
                                >
                                    Purchase
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
}

function PaymentMethodOptionLabel ({ label, cardBrand, expiryDate }) {
    return (
        <div className="flex gap-2 py-1 items-center">
            <img className="h-[25px]" src={`/${cardBrand}.png`} alt="" />
            <div className="flex flex-col justify-center">
                <p className="card-number m-0 leading-[1] text-[13px] font-semibold">{label}</p>
                <p className="text-slate-500 leading-[1] text-[12px] m-0 text-sm">{expiryDate}</p>
            </div>
        </div>
    );
}
