import React from "react";
import { Skeleton } from "@chakra-ui/react";

const LoadingMessagesComponent: React.FC = () => {
    return (
        <div className="">
            <div className="mb-6">
                <Skeleton width="100%" height="20px" marginBottom={"5px"} />
                <Skeleton width="100%" height="100px" />
            </div>
            <div className="mb-6">
                <Skeleton width="100%" height="20px" marginBottom={"5px"} />
                <Skeleton width="100%" height="100px" />
            </div>
            <div className="mb-6">
                <Skeleton width="100%" height="20px" marginBottom={"5px"} />
                <Skeleton width="100%" height="100px" />
            </div>
            <div className="mb-6">
                <Skeleton width="100%" height="20px" marginBottom={"5px"} />
                <Skeleton width="100%" height="100px" />
            </div>
            <div className="mb-6">
                <Skeleton width="100%" height="20px" marginBottom={"5px"} />
                <Skeleton width="100%" height="100px" />
            </div>
        </div>
    );
};

export default LoadingMessagesComponent;
