import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PaymentMethodModel } from "../../models/payment.model";

interface PaymentMethodState {
    data: PaymentMethodModel[];
    defaultPaymentMethod: PaymentMethodModel | null;
    loading: boolean;
    error: string | null;
}

const initialState: PaymentMethodState = {
    data: [],
    defaultPaymentMethod: null,
    loading: false,
    error: null
};

const paymentMethodSlice = createSlice({
    name: "paymentMethod",
    initialState,
    reducers: {
        setLoadingPaymentMethods (state) {
            state.loading = true;
            state.error = null;
        },
        setPaymentMethods (state, action: PayloadAction<PaymentMethodModel[]>) {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        addPaymentMethodToState (state, action: PayloadAction<PaymentMethodModel>) {
            state.data.push(action.payload);
        },
        setDefaultPaymentMethod (state, action: PayloadAction<PaymentMethodModel>) {
            state.defaultPaymentMethod = action.payload;
        },
        updatePaymentMethod (state, action: PayloadAction<any>) {
            state.data = state.data.map((pm) => {
                if (pm.id === action.payload.id) {
                    return { ...pm, ...action.payload };
                }
                return pm;
            });
        },
        setPaymentDefaultPaymentMethod (state, action: PayloadAction<string>) {
            state.data = state.data.map((pm) => {
                if (pm.id === action.payload) {
                    return { ...pm, is_default: "yes" };
                }
                return { ...pm, is_default: "no" };
            });
        },
        deletePaymentMethodFromState (state, action: PayloadAction<string>) {
            state.data = state.data.filter((pm) => pm.id !== action.payload);
        }
    }
});

export const { setDefaultPaymentMethod, addPaymentMethodToState, setPaymentMethods, setLoadingPaymentMethods, updatePaymentMethod, setPaymentDefaultPaymentMethod, deletePaymentMethodFromState } =
    paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
