import { createSlice } from "@reduxjs/toolkit";
// import { FolderModel } from "../../models/folder.model";
import { type SearchModel } from "../../models/search.model";

interface SearchesSlice {
    queries: SearchModel[];
}

const initialState: SearchesSlice = {
    queries: []
};

const userQueriesSlice = createSlice({
    name: "queries",
    initialState,
    reducers: {
        populateUserQueries: (state, action) => {
            state.queries = [...action.payload];
        },
        addUserQuery: (state, action) => {
            state.queries = [...state.queries, action.payload];
        },
        updateLastUserQuery: (state, action) => {
            state.queries = [...state.queries].map((response, index) => {
                if (
                    index === state.queries.length - 1 &&
                    response.question === action.payload.question
                ) {
                    return action.payload;
                }
                return response;
            });
        },
        updateUserQuery: (state, action) => {
            state.queries = [...state.queries].map((response) => {
                if (response.id === action.payload.id) {
                    return action.payload;
                }
                return response;
            });
        }
    }
});

// Export actions
export const {
    addUserQuery,
    updateUserQuery,
    updateLastUserQuery,
    populateUserQueries
} = userQueriesSlice.actions;

export default userQueriesSlice.reducer;
