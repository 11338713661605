import { Tag, Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { type PaymentMethodModel } from "../../models/payment.model";

interface PaymentMethodRecordProps {
    data: PaymentMethodModel;
    onDelete: () => void;
    onSetAsDefault: () => void;
}

export default function PaymentMethodTile ({ data, onDelete, onSetAsDefault }: PaymentMethodRecordProps) {
    const deleteItem = () => {
        // emit delete action
        if (typeof onDelete === "function") {
            onDelete();
        }
    };

    const setAsDefault = () => {
        // emit set as default action
        if (typeof onSetAsDefault === "function") {
            onSetAsDefault();
        }
    };

    return (
        <div className="payment-list border-b flex mb-[10px] justify-between items-center pb-[10px]">
            <div className="card-details flex gap-2">
                <img className="h-[35px] w-[45px]" src={`/${data.card_brand}.png`} alt="" />
                <div className="card pt-[2px]">
                    <h4 className="card-number m-0 leading-[1] mb-[2px] font-[400]">**** **** **** {data.card_extract}</h4>
                    <p className="text-slate-500 text-[13px] m-0 text-sm leading-[1] font-[400]">{data.expiry_date}</p>
                </div>
            </div>

            <div>{data.is_default === "yes" && <Tag colorScheme="blue">Default</Tag>}</div>
            <div className="action">
                <Menu>
                    <MenuButton as={Button} variant="ghost" size={"sm"} rightIcon={<FiChevronDown />}>
                        Actions
                    </MenuButton>
                    <MenuList>
                        {data.is_default !== "yes" && <MenuItem onClick={setAsDefault}>Make Default</MenuItem>}
                        <MenuItem onClick={deleteItem} color={"red.500"}>
                            Delete
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </div>
    );
}
