// listUserOrganizationApi
import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHttpClient } from "../../utils/http.utils";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import hotToast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { type AccountData, setAccountCredentials } from "../../store/slices/account.slice";
import type { AuthState } from "../../store/slices/auth.slice";

interface NavigateBackendModalProps {
    isOpen: boolean;
    tokenExpiration?: string;
    onClose: () => void;
}

const NavigateBackendModal: React.FC<NavigateBackendModalProps> = (props) => {
    const modalDis = useDisclosure();
    const {
        listUserOrganizationApi,
        listOrganizationMemberApi,
        acceptOrganizationMemberInvitationApi,
        checkBackendOwnershipAccessApi,
        activateBackendMembershipApi
    } = useHttpClient();
    const dispatch = useDispatch();
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [organizationInvitations, setOrganizationInvitations] = useState<any[]>([]);
    const [isInvitationLoading, setIsInvitationLoading] = useState<boolean>(false);
    const [hasOwnedBackendAccess, setHasOwnedBackendAccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const { data: authUserProfile } = useSelector((state: any) => state.account);
    const authState: AuthState = useSelector((state: any) => state.auth);
    const { user: authUser } = authState;

    useEffect(() => {
        loadOrganizations();
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            modalDis.onOpen();
        } else {
            modalDis.onClose();
        }
    }, [props.isOpen]);

    useEffect(() => {
        (async () => {
            if (authUserProfile?.id) {
                // Check user owned Backend Account
                if (authUserProfile?.backend_member === "yes") {
                    setHasOwnedBackendAccess(true);
                } else {
                    // Check whether user has backend access
                    setHasOwnedBackendAccess(await checkBackendOwnershipAccessApi());
                }
            }
        })();
    }, [authUserProfile]);

    const loadOrganizations = async () => {
        try {
            if (organizations.length === 0) {
                const data = await listUserOrganizationApi();
                setOrganizations(data);
            }
            // fetch organizations
            const invitations: any[] = await listOrganizationMemberApi({
                search_criteria: {
                    status: "invited"
                },
                populate: {
                    organization: true
                }
            });
            setOrganizationInvitations(invitations);
        } catch (e) {
            console.log(e);
            hotToast.error("Cannot load your organizations");
        }
    };

    const closeViewModal = () => {
        modalDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const openBackend = (organizationId: string) => {
        // if (authUser?.token && authUser?.expiresAt) {
        //     // window.open(`${process.env.REACT_APP_NEO_AI_URL!}/oauth_callback?access_token=${authUser?.token}&org=${organizationId}&expires_at=${authUser?.expiresAt}`, "_blank")?.focus();
        // }
        window.open(`${process.env.REACT_APP_NEO_AI_URL!}/?org=${organizationId}`, "_blank")?.focus();
        closeViewModal();
    };

    const openOwnBackend = async () => {
        if (authUser?.email) {
            if (authUserProfile?.backend_member !== "yes") {
                // Activate Backend access
                setIsLoading(true);
                const operatingRoomMembershipActivated = await activateBackendMembershipApi();
                setIsLoading(false);

                if (operatingRoomMembershipActivated) {
                    dispatch(setAccountCredentials({ ...authUserProfile as AccountData, backend_member: "yes" }));
                    hotToast.success("Your operating room has been activated");
                } else {
                    hotToast.error("Could not activate your operating Room");
                }
            } else {
                // window.open(`${process.env.REACT_APP_NEO_AI_URL}/oauth_callback?access_token=${authUser?.token}&expires_at=${authUser?.expiresAt}`, "_blank")?.focus();
                window.open(process.env.REACT_APP_NEO_AI_URL, "_blank")?.focus();
                closeViewModal();
            }
        }
    };

    const acceptInvitation = async (invitationObj: any, accept: "yes" | "no") => {
        setIsInvitationLoading(true);
        hotToast.promise(acceptOrganizationMemberInvitationApi(invitationObj.organization?.id as string, accept), {
            error: () => {
                setIsInvitationLoading(false);
                return "Cannot accept invitation at this time, Please try again later";
            },
            success: () => {
                setIsInvitationLoading(false);
                // load the user's organizations
                if (accept === "yes") {
                    loadOrganizations();
                }
                // remove object from state
                setOrganizationInvitations((prevState) => prevState.filter((inv) => inv.id !== invitationObj.id));
                return "You invitation was accepted";
            },
            loading: accept === "yes" ? "Accepting invitation.." : "Declining invitation.."
        });
    };

    return (
        <Modal size="lg" isOpen={modalDis.isOpen} onClose={closeViewModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Choose Organization</ModalHeader>
                <ModalBody pt={0}>
                    <div className="min-h-[300px]">
                        <div className="min-h-auto">
                            {organizations.map((org: any, index) => (
                                <div
                                    onClick={() => openBackend(org.id as string)}
                                    key={"org" + index}
                                    aria-label="button"
                                    className="org border px-3 border-white cursor-pointer hover:bg-gray-50 hover:border-gray-300 hover:rounded-[8px] py-3 flex gap-[12px]"
                                >
                                    <div className="icon">
                                        <div className="h-[45px] w-[45px] bg-gray-400 rounded-[8px] flex justify-center items-center">
                                            <FaBuilding size="25px" className="text-white" />
                                        </div>
                                    </div>
                                    <div className="details">
                                        <h2 className="text-[16px]">{org?.name}</h2>
                                        <h4 className="text-[13px] gap-[0px] flex items-center text-slate-400">
                                            <MdOutlineLocationOn size="15px" />
                                            {org?.address?.city}, {org?.address?.country}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {organizationInvitations.length > 0 && (<div className="min-h-[100px]">
                            <h2 className="text-[16px] mb-[5px]">Invitations ({organizationInvitations.length})</h2>
                            {organizationInvitations.map((invitation: any, index) => (
                                <div
                                    key={"org" + index}
                                    aria-label="button"
                                    className="border px-3 border-white cursor-pointer hover:bg-gray-50 hover:border-gray-300 hover:rounded-[8px] py-3 flex gap-[12px] justify-between items-center"
                                >
                                    <div className="flex gap-[10px] items-center">
                                        <div className="icon">
                                            <div className="h-[45px] w-[45px] bg-gray-400 rounded-[8px] flex justify-center items-center">
                                                <FaBuilding size="25px" className="text-white" />
                                            </div>
                                        </div>
                                        <div className="details">
                                            <h2 className="text-[16px] leading-[1]">{invitation.organization?.name}</h2>
                                            <h4 className="text-[13px] gap-[0px] flex items-center text-slate-400">
                                                {invitation.role}
                                            </h4>
                                        </div>
                                    </div>
                                    <ButtonGroup>
                                        <Button variant="ghost" size="sm" isDisabled={isInvitationLoading} colorScheme="blue" onClick={async () => await acceptInvitation(invitation, "yes")}>Accept</Button>
                                        <Button variant="ghost" size="sm" isDisabled={isInvitationLoading} colorScheme="red" onClick={async () => await acceptInvitation(invitation, "no")}>Reject</Button>
                                    </ButtonGroup>
                                </div>
                            ))}
                        </div>)}
                    </div>
                    {hasOwnedBackendAccess && <div className="own-organization-section">
                        <Button
                            onClick={async () => await openOwnBackend()}
                            isLoading={isLoading}
                            aria-label="button"
                            className="w-full"
                        >
                            <div className="details">
                                {authUserProfile?.backend_member === "yes"
                                    ? <h4 className="text-[16px]">Enter your Operating Room</h4>
                                    : <h4 className="text-[16px]">Activate your Operating Room</h4>
                                }
                            </div>
                        </Button>
                    </div>}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NavigateBackendModal;
