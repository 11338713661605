import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast";

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            200: "#f7fafc",
            300: "#1a202c",
            400: "#1a202c",
            500: "#1a202c",
            600: "#1a202c",
            700: "#1a202c",
            800: "#1a202c",
            900: "#1a202c"
        }
    }
});

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        // Set environment version
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", process.env.REACT_APP_URL!],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
            // Check if the error has Axios-related properties
            if (hint.originalException && (
                typeof hint.originalException === "object" && (
                    (hint.originalException as any).name === "AxiosError" &&
                    "config" in hint.originalException &&
                    "request" in hint.originalException
                )
            )) {
                // Assuming this is an Axios error, ignore it
                return null;
            }
            // Otherwise, allow Sentry to send the event
            return event;
        }
    });
}

const root = ReactDOM.createRoot(
    document.getElementById("root")!
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <RouterProvider router={router} />
            </ChakraProvider>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    // Define default options
                    className: "bg-black text-white",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff"
                    }
                }}
            />
        </Provider>
        {/* <div className="app_support-warning"> */}
        {/*    <div className="text-center"> */}
        {/*        <img src="/neo_ai_logo_black.png" alt="" className="app_logo"/> */}
        {/*        <p className="text-slate-500 text-sm mt-6"> */}
        {/*            This application is currently not supported on Mobile/Tablet. Please switch to a desktop. */}
        {/*        </p> */}
        {/*    </div> */}
        {/* </div> */}
        {/* <App /> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
