import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RoomMessage } from "../../models/room.model";

export interface RoomMessageWithLoading {
    message: RoomMessage;
    loading: boolean;
    localID: string;
}

const initialState: RoomMessageWithLoading[] = [];

const messageSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setMessages: (_state, action: PayloadAction<RoomMessageWithLoading[]>) => {
            return action.payload;
        },
        addMessage: (state, action: PayloadAction<RoomMessageWithLoading>) => {
            const existingMessage = state.find((msg) => msg.message.id === action.payload.message.id);

            if (!existingMessage) {
                // return [...state, action.payload];
                return [action.payload, ...state];
            }
            return state;
        },
        updateMessageLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
            return state.map((msg) => (msg.message.id === action.payload.id ? { ...msg, loading: action.payload.loading } : msg));
        },

        removeMessage: (state, action: PayloadAction<string>) => {
            return state.filter((msg) => msg.message.id !== action.payload);
        },

        confirmMessage: (state, action: PayloadAction<{ localID: string; confirmedID: string }>) => {
            return state.map((msg) =>
                msg.localID === action.payload.localID
                    ? {
                        ...msg,
                        message: {
                            ...msg.message,
                            id: action.payload.confirmedID
                        },
                        loading: false
                    }
                    : msg
            );
        },
        clearMessages: (_state) => {
            return [];
        }
    }
});

export const { addMessage, updateMessageLoading, setMessages, removeMessage, confirmMessage, clearMessages } = messageSlice.actions;
export default messageSlice.reducer;
