import { GiBrainStem } from "react-icons/gi";
import { AiOutlineCalendar, AiOutlineEye, AiOutlineInteraction } from "react-icons/ai";
import { SiObservable } from "react-icons/si";
// import { Button } from "@chakra-ui/react";
import React from "react";
// import "../../styles/BrainActivity.component.scss";
import { GrStatusCritical, GrTools } from "react-icons/gr";
import { FaThinkPeaks } from "react-icons/fa";
import { type BrainScanSequenceMessageGroup } from "../../models/brain-scan.model";
import { useDispatch, useSelector } from "react-redux";
import { clearSequence, selectActivePersonaId } from "../../store/slices/brain-scan.slice";

interface SequenceProps {
    sequence: BrainScanSequenceMessageGroup;
}

export default function BrainScanSequence ({ sequence }: SequenceProps) {
    const dispatch = useDispatch();
    const activePersonaId = useSelector(selectActivePersonaId);
    const renderTextWithNewlines = (text: string) => {
        const lines = text.split("\n");

        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const clearSequenceMessages = () => {
        dispatch(clearSequence({
            persona_id: activePersonaId,
            collaboration_app_id: sequence.collaboration_app_id
        }));
    };

    if (sequence.message_list.length <= 0) {
        return <></>;
    }

    // <div className="cycle-container pt-3 bg-white mb-3">
    //                     {/*<div className="flex items-center gap-2">*/}
    //                     {/*    <p className="text-sm font-semibold">Cycle 1</p>*/}
    //                     {/*    <p className="text-slate-500 text-xs">{formatDate((new Date()).toISOString(), "LLL")}</p>*/}
    //                     {/*</div>*/}
    //                     <div className="pl-3 cycles"></div>
    return (
        <div className="thoughts-sequence-container cycle-container">
            <div className="thoughts-wrapper">
                <div className="thoughts-header flex justify-between">
                    <div className="leading flex items-center gap-1">
                        <button className="header-tag" title={"sequence " + sequence.collaboration_app_id}>
                            sequence {sequence.collaboration_app_id}
                        </button>
                        <button className="header-tag">interrupt</button>
                    </div>
                    <div className="trailing">
                        <button className="header-tag" onClick={clearSequenceMessages}>forget</button>
                    </div>
                </div>
                <div className="thoughts-proper">
                    <div className="cell">
                        {sequence.message_list
                            .map((message, index) => {
                                const elements: JSX.Element[] = [];

                                if (message.observation) {
                                    elements.push(
                                        <div key={"observation-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <AiOutlineEye className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Observation</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.observation)}</p>
                                        </div>
                                    );
                                }

                                if (message.thought) {
                                    elements.push(
                                        <div key={"thought-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <GiBrainStem className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Thought</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.thought)}</p>
                                        </div>
                                    );
                                }

                                if (message.reasoning) {
                                    elements.push(
                                        <div key={"reasoning-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <FaThinkPeaks className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Reasoning</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.reasoning)}</p>
                                        </div>
                                    );
                                }

                                if (message.plan) {
                                    elements.push(
                                        <div key={"plan-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <AiOutlineCalendar className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Plan</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.plan)}</p>
                                        </div>
                                    );
                                }

                                if (message.action) {
                                    elements.push(
                                        <div key={"action-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <AiOutlineInteraction className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Action</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.action)}</p>
                                        </div>
                                    );
                                }

                                if (message.criticism) {
                                    elements.push(
                                        <div key={"criticism-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <GrStatusCritical className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Criticism</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.criticism)}</p>
                                        </div>
                                    );
                                }

                                if (message.tool_result) {
                                    elements.push(
                                        <div key={"tool-result-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <GrTools className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Tool</h1>
                                            </div>
                                            <p className="cell-item-description">{renderTextWithNewlines(message.tool_result)}</p>
                                        </div>
                                    );
                                }

                                if (message.content_log) {
                                    elements.push(
                                        <div key={"content-log-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <SiObservable className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Activity</h1>
                                            </div>
                                            <div className="cell-item-description">
                                                {message.content_log?.general_text && <div>{renderTextWithNewlines(message.content_log.general_text as string)}</div>}

                                                {message.content_log?.action && (
                                                    <div>
                                                        {message.content_log.action}: {renderTextWithNewlines(message.content_log.action_input as string)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }

                                if (message.evaluation) {
                                    elements.push(
                                        <div key={"evaluation-" + index} className="cell-item">
                                            <div className="cell-item-head flex items-center gap-1">
                                                <div className="icon-box">
                                                    <SiObservable className="icon" size="18px" />
                                                </div>
                                                <h1 className="cell-item-title">Evaluation</h1>
                                            </div>
                                            <div className="cell-item-description">
                                                <div>Comment: {message.evaluation.comment}</div>
                                                <br />
                                                <div>Commentary: {message.evaluation.commentary}</div>
                                                <br />
                                                <div>Tool Evaluation Score: {message.evaluation.tool_evaluation_score}</div>
                                                <div>Goal Evaluation Score: {message.evaluation.goal_evaluation_score}</div>
                                            </div>
                                        </div>
                                    );
                                }

                                return elements;
                            })
                            .flat()}
                    </div>
                </div>
            </div>
        </div>
    );
}
