import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import "../styles/LayoutHeader.component.scss";
import { formatDate } from "../utils/date.utils";
import ProfileDropdown from "./ProfileDropdown";
import { NavLink } from "react-router-dom";
// import { type AuthState } from "../store/slices/auth.slice";
import { useSelector } from "react-redux";
import { type AccountState } from "../store/slices/account.slice";
import { getUserName } from "../utils/user.utils";

interface IHeaderProps {
    middleContent?: any;
}
const Header = (props: IHeaderProps) => {
    // const authState: AuthState = useSelector((state: any) => state.auth);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const [firstTimeSignIn, setFirstTimeSignIn] = useState<boolean>(true);

    useEffect(() => {
        if (window) {
            const firstTimeSignInRef = window.localStorage.getItem("firstTimeSignIn");
            if (firstTimeSignInRef) {
                setFirstTimeSignIn(false);
            } else {
                setFirstTimeSignIn(true);
                window.localStorage.setItem("firstTimeSignIn", "true");
            }
        }
    }, []);

    return (
        <header className="layout-header px-[10px] md:px-[40px]">
            <div className="header-leading flex items-center gap-4">
                <ProfileDropdown />
            </div>
            <div className="header-center">
                {props.middleContent ? (
                    props.middleContent
                ) : (
                    <>
                        <div className="flex items-center gap-2">
                            <NavLink to={"/"}>
                                <Avatar name="Neo AI" src="https://assets.neoworlder.com/img/neoworlder_icon_black.png" size="18" className="logo-avatar" />
                            </NavLink>
                            <p className="welcome-date">Today,&nbsp;{formatDate("", "D MMMM YYYY")}</p>
                        </div>
                        <p className="welcome-message">
                            Welcome&nbsp;{firstTimeSignIn ? "" : "back"}, {getUserName(accountState.data)}.
                        </p>
                    </>
                )}
            </div>
            <div className="header-actions"></div>
        </header>
    );
};

export default Header;
