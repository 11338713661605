import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { IoIosAddCircle } from "react-icons/io";
import "../styles/ControlGroup.component.scss";
import SearchInput from "./SearchInput";
import debounce from "lodash.debounce";

import ResponseFeedback from "./ResponseFeedback";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { PiCircleHalfTiltThin } from "react-icons/pi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { CiBellOn } from "react-icons/ci";
import { LordIcon } from "./icons/LordIcon";
import { Button, FormControl, Input, Textarea } from "@chakra-ui/react";
import Select from "react-select";
import ControlGroupPersonaCard from "./ControlGroupPersonaCard";
import { ControlGroupNotifications } from "./ControlGroupNotifications";
import { useHttpClient } from "../utils/http.utils";
import { type PersonaModel } from "../models/persona.model";
import { useSelector } from "react-redux";
import { type PersonaState } from "../store/slices/persona.slice";
import { getPersonaFullName } from "../utils/strings.utils";
import hotToast from "react-hot-toast";

// interface UserPersonaType { user?: string[]; persona?: string[] }

const ControlGroup = () => {
    const { searchInviteesApi, createRoomApi } = useHttpClient();
    const personaState: PersonaState = useSelector((state: any) => state.personas);
    const { data: authUser } = useSelector((state: any) => state.account);

    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [showEntities, setShowEntities] = useState(false);
    const [isGroupMenuOpen, setIsGroupMenuOpen] = useState(false);
    const [selectedInvitees, setSelectInvitees] = useState<any[]>([]);
    const [showInfo, setShowInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [personasSearchList, setPersonasSearchList] = useState<PersonaModel[]>([]);
    const [groupPeopleOptions, setGroupPeopleOptions] = useState<Array<{ label: string; value: string }>>([]);

    const groupFormContainerRef = useRef<HTMLDivElement>(null);
    const roomFormContainerRef = useRef<HTMLDivElement>(null);
    const groupTitleRef = useRef(null);
    const groupDescriptionRef = useRef(null);

    // Close the dropdown if the user clicks outside of it
    const handleOutsideClick = (e: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
            setIsActionMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        const personaOptions = personaState.personas.map((persona) => {
            return {
                label: getPersonaFullName(persona),
                value: persona.id!,
                option_type: "persona"
            };
        });

        setSelectInvitees([{ label: "Me", value: authUser?.username || "", ...authUser }]);

        setGroupPeopleOptions([{ label: "Me", value: authUser?.username || "", ...authUser }, ...personaOptions]);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [personaState.personas]);

    useEffect(() => {
    /**
     * This hook listens to the changes of the open menus state variable
     * This hook is meant to show the create group or the persona room form
     *  on the top or bottom depending on the position of the control menu..
     */
        if (roomFormContainerRef.current && isActionMenuOpen) {
            const div = wrapperRef.current!;
            const rect = div.getBoundingClientRect();
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

            if ((rect.bottom + 420) > windowHeight) {
                roomFormContainerRef.current.style.bottom = "calc(100% + 10px)";
                roomFormContainerRef.current.style.top = "unset";
            } else {
                roomFormContainerRef.current.style.top = "calc(100% + 10px)";
            }
        }

        if (groupFormContainerRef.current && isGroupMenuOpen) {
            const div = wrapperRef.current!;
            const rect = div.getBoundingClientRect();
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

            if ((rect.bottom + 420) > windowHeight) {
                groupFormContainerRef.current.style.bottom = "calc(100% + 10px)";
                groupFormContainerRef.current.style.top = "unset";
            } else {
                groupFormContainerRef.current.style.top = "calc(100% + 10px)";
            }
        }
    }, [isActionMenuOpen, isGroupMenuOpen]);

    const onSelectInvitees = (val: readonly any[]) => {
        setSelectInvitees([...val]);
    };

    const closeAllDropDowns = () => {
        setIsGroupMenuOpen(false);
        setIsActionMenuOpen(false);
        setShowNotifications(false);
    };

    const toggleDropdown = (event) => {
        setIsGroupMenuOpen(false);
        setShowNotifications(false);
        event.stopPropagation(); // Stop the event from propagating up
        setIsActionMenuOpen((prev) => !prev);
    };

    const toggleGroupDropDown = (event) => {
        setIsActionMenuOpen(false);
        setShowNotifications(false);
        event.stopPropagation(); // Stop the event from propagating up
        setIsGroupMenuOpen((prev) => !prev);
    };

    const toggleNotifications = (event) => {
        setIsGroupMenuOpen(false);
        setIsActionMenuOpen(false);
        event.stopPropagation(); // Stop the event from propagating up
        setShowNotifications((prev) => !prev);
    };

    const onSearchInput = (value: string) => {
        if (!value) {
            setPersonasSearchList([]);
            return;
        }
        const personas = personaState.personas.filter((persona) => {
            return getPersonaFullName(persona).toLowerCase().includes(value.toLowerCase());
        });
        // set persona list
        setPersonasSearchList(personas);
    };

    const createGroup = () => {
        const title = (groupTitleRef.current as any).value;
        const description = (groupDescriptionRef.current as any).value;
        /**
         * Simple validation
         */
        if (!title) {
            hotToast.error("Please enter the title of your group");
            return;
        }

        if (!description) {
            hotToast.error("Please enter the description of your group");
            return;
        }

        if (selectedInvitees.length <= 1) {
            hotToast.error("Please invite at least one other person");
            return;
        }

        const groupedIds = selectedInvitees.reduce(
            (acc, item) => {
                if (item.option_type === "persona" && (item.id || item.value)) {
                    acc.persona.push(item.id || item.value);
                } else if (item.id || item.value) {
                    acc.user.push(item.id || item.value);
                }
                return acc;
            },
            { persona: [], user: [] }
        );

        // const userPersona: UserPersonaType[] = [];
        if (groupedIds.persona.length < 1) {
            hotToast.error("You need to invite at least one persona to your group");
            return;
        }
        /**
         * Return an error if the total invited members is less that three
         */
        if (groupedIds.persona.length + groupedIds.user.length < 3) {
            hotToast.error("You need to invite at least three members to your group");
            return;
        }

        const requestBody = {
            // invited_list: { user: groupedIds.user },
            // invitee_list: { persona: groupedIds.persona },
            user_list: groupedIds.user,
            persona_list: groupedIds.persona,
            title,
            description,
            status: "active"
        };

        const createPromise = createRoomApi(requestBody);

        closeAllDropDowns();

        hotToast.promise(createPromise, {
            loading: `Creating Group`,
            success: "Successfully created group",
            error: "Failed to create group"
        });
    };

    const debouncedSearch = debounce(async (value: string) => {
        if (!value) {
            setGroupPeopleOptions([
                {
                    label: "Me",
                    value: authUser?.username || "",
                    ...authUser
                },
                ...personaState.personas.map((persona) => ({
                    label: String(getPersonaFullName(persona)),
                    value: String(persona.id),
                    option_type: "persona",
                    ...persona
                }))
            ]);
            return;
        }

        setIsLoading(true); // Begin loading

        try {
            const responseData = await searchInviteesApi(value);
            const inviteeOptions = responseData.map((invitee) => ({
                label: String(invitee.email),
                value: String(invitee.id),
                ...invitee
            }));

            setGroupPeopleOptions([
                {
                    label: "Me",
                    value: authUser?.username || "",
                    ...authUser
                },
                ...inviteeOptions
            ]);
        } catch (error) {
            console.error("Failed to fetch invitees:", error);
            hotToast.error("Failed to fetch invitees.");
        }

        setIsLoading(false); // End loading
    }, 300); // 300ms delay

    const handleSearchInputChange = (value) => {
        debouncedSearch(value);
    };

    return (
        <Draggable nodeRef={wrapperRef}>
            <div className="wrapper" onClick={toggleDropdown} ref={wrapperRef}>
                <IoIosAddCircle size={"30px"} />
                <div className="container shadow-sm">
                    <div className="selectAi">
                        {/* <BsPersonPlus className="icon" size={'24px'}/> */}
                        <LordIcon src="/lord-icons/person-plus.json" trigger="hover" colors={{ primary: "#121331", secondary: "#333" }} stroke={40} size={30} />
                        <p className="placeholder text-slate-500">Select sylis or arin</p>
                    </div>
                    <div className="controls">
                        <div className="controls-1 pr-1.5">
                            <BsChevronDown className="icon" size={"16px"} />
                        </div>
                        <div className="mx-1">|</div>
                        <div className="controls-1">
                            <button onClick={toggleGroupDropDown} className="group-btn">
                                create group
                            </button>
                            <button onClick={toggleNotifications} className="notification-section">
                                <CiBellOn className="icon" size={"20px"} />
                                <span className="dot"></span>
                            </button>
                        </div>
                    </div>
                </div>
                {isActionMenuOpen && (
                    <div ref={roomFormContainerRef} className="dropdown-content" onClick={(e) => e.stopPropagation()}>
                        <div className="px-2 pt-1">
                            <SearchInput onChange={onSearchInput} />
                        </div>

                        <div className="personas pt-1">
                            {personasSearchList.map((persona, index) => {
                                return (
                                    <ControlGroupPersonaCard
                                        key={`persona-${index}`}
                                        personaId={persona.id}
                                        name={getPersonaFullName(persona)}
                                        avatarImageUrl="/amanda.png"
                                        username={persona.username ?? ""}
                                        pricing={"$0.003/word"}
                                        isVerified={true}
                                        type={"arin"}
                                        rating={105}
                                    />
                                );
                            })}
                            {/* <ControlGroupPersonaCard */}
                            {/*    name="Luka adameve" */}
                            {/*    avatarImageUrl="https://xsgames.co/randomusers/assets/avatars/male/18.jpg" */}
                            {/*    username={"@lindaduke"} */}
                            {/*    pricing={"$0.003/word"} */}
                            {/*    isVerified={true} */}
                            {/*    type={"sylis"} */}
                            {/*    rating={105} */}
                            {/* /> */}

                            {/* <ControlGroupPersonaCard */}
                            {/*    name="paul dinklage" */}
                            {/*    avatarImageUrl="https://xsgames.co/randomusers/assets/avatars/male/19.jpg" */}
                            {/*    username={"@pauldinklage"} */}
                            {/*    isVerified={true} */}
                            {/* /> */}
                        </div>

                        {/* <ResponseFeedback */}
                        {/*    icon={<PiSmileySadThin style={{color: "var(--gray-500)"}} size={'24px'}/>} */}
                        {/*    title="Sorry, no Ai entities available to you" */}
                        {/* /> */}

                        <div className="grow-ai-entities">
                            <ResponseFeedback
                                icon={
                                    <PiCircleHalfTiltThin style={{ color: "var(--gray-500)" }} size={"24px"} /> /**/
                                    // <LordIcon
                                    //     src="https://cdn.lordicon.com/jmkpuued.json"
                                    //     trigger="hover"
                                    //     colors={{primary: "#121331", secondary: "#333"}}
                                    //     size={30}
                                    // />
                                }
                                hideBorder={!showEntities}
                                title="grow AI entities"
                                feedback={"Get access to create synthetic life?"}
                            />

                            <button onClick={() => setShowEntities(!showEntities)} className="ai-entities-btn pt-2">
                                {showEntities ? <BsChevronUp className="icon" size={"16px"} /> : <BsChevronDown className="icon" size={"16px"} />}
                            </button>
                        </div>
                        {showEntities && (
                            <div className="entities-options pb-3">
                                <div className="option-tile">
                                    <div className="icon">
                                        <AiOutlineCheckCircle size={22} />
                                    </div>
                                    <div className="text">
                                        <p className="title">Lootian License</p>
                                        <p className="subtitle">
                                            Owners of a district or unit license from our laboratory get automatic access to Neo Al's operating room.&nbsp;
                                            <span className="option-tile-link">Get now?</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="option-tile">
                                    <div className="icon">
                                        <AiOutlineCheckCircle size={22} />
                                    </div>
                                    <div className="text">
                                        <p className="title">Digital Brain Surgeons</p>
                                        <p className="subtitle">
                                            Accredited experts to Neo Al's platform able to assist in crafting digital life.&nbsp;
                                            <span className="option-tile-link">Learn more.</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="option-tile">
                                    <div className="icon">
                                        <AiOutlineCheckCircle size={22} />
                                    </div>
                                    <div className="text">
                                        <p className="title">User Reward Tokens</p>
                                        <p className="subtitle">
                                            Stake MDR to get access.&nbsp;
                                            <span className="option-tile-link">Learn more.</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="option-tile">
                                    <div className="icon">
                                        <AiOutlineCheckCircle size={22} />
                                    </div>
                                    <div className="text">
                                        <p className="title">By Invitation</p>
                                        <p className="subtitle">
                                            For commercial enterprises only.&nbsp;
                                            <span className="option-tile-link">Contact Us.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isGroupMenuOpen && (
                    <div ref={groupFormContainerRef} className="dropdown-content" onClick={(e) => e.stopPropagation()}>
                        <div className="px-1">
                            <div className="form-group">
                                <div className="form-label-header">
                                    <p className="form-label">Invite People</p>
                                </div>
                                <FormControl mb={3}>
                                    <Select
                                        defaultValue={selectedInvitees}
                                        isMulti
                                        isClearable={false}
                                        name="colors"
                                        options={groupPeopleOptions}
                                        isLoading={isLoading}
                                        onChange={onSelectInvitees}
                                        onInputChange={handleSearchInputChange}
                                        placeholder={"Type to Add"}
                                        className="arin-react-select-container"
                                        classNamePrefix="arin-react-select"
                                        loadingMessage={() => "Loading..."}
                                        noOptionsMessage={() => (isLoading ? "Loading..." : "No options")}
                                    />
                                </FormControl>
                            </div>

                            <div className="form-group">
                                <div className="form-label-header">
                                    <p className="form-label">Details</p>
                                </div>
                                <FormControl mb={4}>
                                    <Input ref={groupTitleRef} placeholder="Title" />
                                </FormControl>
                                <FormControl>
                                    <Textarea ref={groupDescriptionRef} placeholder="Description" />
                                </FormControl>
                            </div>

                            <div className="actions flex py-2 items-center justify-end">
                                <Button onClick={createGroup} size="sm" colorScheme="brand">
                                    Start Group
                                </Button>
                            </div>

                            <div className="info-container">
                                <p className="info-title">
                                    Info
                                    <button onClick={() => setShowInfo(!showInfo)}>{showInfo ? <BsChevronUp className="icon" /> : <BsChevronDown className="icon" />}</button>
                                </p>
                                {showInfo && (
                                    <p className="info-text">
                                        This group comprises AI entities sponsored by another entity. You can only invite AI entities and humans that have been approved for this group. The group
                                        header will display the sponsor's name.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {showNotifications && (
                    <div className="dropdown-content" style={{ padding: "10px 0" }} onClick={(e) => e.stopPropagation()}>
                        <ControlGroupNotifications
                            title="VIP room"
                            subtitle={"Invited by @jamesduke"}
                            secondSubtitle={
                                <p>
                                    Sponsored by <span className="text-blue-500">NeoWorlder Inc.</span>
                                </p>
                            }
                            unread={false}
                        />
                        <ControlGroupNotifications title="Katy Adameve" subtitle="15 Unread Notifications" secondSubtitle={"T$0.003/word"} hideAcceptButton={true} unread={true} />
                    </div>
                )}
            </div>
        </Draggable>
    );
};

export default ControlGroup;
