import React, { useEffect, useRef, useState } from "react";
import { type Socket } from "socket.io-client";

import { BiChevronUp } from "react-icons/bi";
import "../../styles/BrainActivity.component.scss";
import { LordIcon } from "../icons/LordIcon";
// import { AiOutlineEye, AiOutlineCalendar } from "react-icons/ai";
import { type BrainScanSequenceMessage, type BrainScanSequenceMessageGroup, type BrainScanThoughtMessage } from "../../models/brain-scan.model";
import {
    type ChainItem,
    addSequenceMessage,
    addThoughtMessage,
    clearChain,
    removeChainItem,
    selectActiveChainHistory,
    selectActivePersona,
    selectActivePersonaId,
    selectActiveSequenceList,
    selectActiveThoughtMessageList,
    selectPersonaList,
    setActivePersonaId
} from "../../store/slices/brain-scan.slice";
import { useDispatch, useSelector } from "react-redux";
import BrainScanSequence from "./BrainScanSequence";
import { Button, SkeletonCircle, useToast } from "@chakra-ui/react";
import { type PersonaModel } from "../../models/persona.model";
import { initSocket } from "../../utils/socket.utils";
import { addWatchingCollabId, selectActiveRoomId, selectWatchingCollabIdList } from "../../store/slices/rooms.slice";
import { replaceValues } from "../../utils/strings.utils";
import brainScanMessages from "../../config/brainscan-messages";
import { useNavigate } from "react-router-dom";

export default function BrainActivity () {
    const [hasImageLoaded, setHasImageLoaded] = useState(false);
    const activeSequenceList: BrainScanSequenceMessageGroup[] | undefined = useSelector(selectActiveSequenceList);
    const activeThoughtMessageList: BrainScanThoughtMessage[] | undefined = useSelector(selectActiveThoughtMessageList);
    const activePersona: PersonaModel | undefined = useSelector(selectActivePersona);
    const activeChainList: ChainItem[] | undefined = useSelector(selectActiveChainHistory);
    const activeRoomId: string = useSelector(selectActiveRoomId);
    const watchingCollabIdList = useSelector(selectWatchingCollabIdList);
    const personaList: PersonaModel[] = useSelector(selectPersonaList);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const socketRef = useRef<Socket | null>(null);
    const [displayMode, setDisplayMode] = useState<"cells" | "chain" | "all">("all");
    const navigate = useNavigate();
    const toast = useToast();

    // const [recentUserInteraction, setRecentUserInteraction] = useState<AutoInteractionMessage | null>()

    const dispatch = useDispatch();

    const onChangePersona = (val: string) => {
        dispatch(setActivePersonaId(val));
    };

    const toggleCellsDisplayMode = () => {
        if (displayMode === "cells") {
            setDisplayMode("all");
        } else {
            setDisplayMode("cells");
        }
    };

    const toggleChainDisplayMode = () => {
        if (displayMode === "chain") {
            setDisplayMode("all");
        } else {
            setDisplayMode("chain");
        }
    };

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = initSocket();
        }

        socketRef.current.connect();

        if (socketRef.current) {
            socketRef.current.on(`intake-outtake-initialization-${activeRoomId}`, (data) => {
                dispatch(
                    addThoughtMessage({
                        body: replaceValues(brainScanMessages.intake_outtake_init.body, "{{", "}}", {
                            user_query: data.original_query
                        }),
                        title: brainScanMessages.intake_outtake_init.title,
                        persona: data.persona
                    })
                );
            });

            socketRef.current.on(`intake-outtake-flow-start-${activeRoomId}`, (data) => {
                dispatch(
                    addThoughtMessage({
                        body: replaceValues(brainScanMessages.intake_flow_init.body, "{{", "}}", {
                            flow: data.flow
                        }),
                        title: brainScanMessages.intake_flow_init.title,
                        persona: data.persona
                    })
                );
            });

            socketRef.current.on(`intake-outtake-completion-${activeRoomId}`, (data) => {
                dispatch(
                    addThoughtMessage({
                        body: replaceValues(brainScanMessages.intake_outtake_flow_completion.body, "{{", "}}", {
                            user_query: data.original_query
                        }),
                        title: brainScanMessages.intake_outtake_flow_completion.title,
                        persona: data.persona
                    })
                );
            });

            socketRef.current.on(`intake-outtake-collab-start-${activeRoomId}`, (data) => {
                dispatch(
                    addThoughtMessage({
                        body: replaceValues(brainScanMessages.intake_outtake_flow_collab_started.body, "{{", "}}", {
                            user_query: data.original_query
                        }),
                        title: brainScanMessages.intake_outtake_flow_collab_started.title,
                        persona: data.persona
                    })
                );
            });

            socketRef.current.on(`collaboration-app-start-${activeRoomId}`, (data) => {
                if (data.collaboration_app) {
                    dispatch(addWatchingCollabId(data.collaboration_app.id as string));
                }
                // Route to thread view
                navigate(`/room/${activeRoomId}/thread/${data.room_message_id}`);
            });

            socketRef.current.on(`collaboration-app-not-found-${activeRoomId}`, (data) => {
                dispatch(
                    addThoughtMessage({
                        body: replaceValues(brainScanMessages.collaboration_app_not_found.body, "{{", "}}", {
                            user_query: data.original_query
                        }),
                        title: brainScanMessages.collaboration_app_not_found.title,
                        persona: data.persona
                    })
                );
            });

            socketRef.current.on("display-error", (data) => {
                if (data.type === "credit_balance_error") {
                    toast({
                        title: "Insufficient Balance",
                        description: "You do not have enough balance to query the persona",
                        status: "error"
                    });
                }
            });

            watchingCollabIdList.forEach((collabId) => {
                socketRef.current.on("collaboration-app-" + collabId, ({ data }: { data: BrainScanSequenceMessage }) => {
                    // if message is an activity message
                    if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log || data.evaluation || data.tool_result)) {
                        dispatch(
                            addSequenceMessage({
                                ...data,
                                collaboration_app_id: collabId,
                                user_input_submitted: true
                            })
                        );
                    }
                });
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.removeAllListeners();
                socketRef.current.disconnect();
            }
        };
    }, [dispatch, watchingCollabIdList]);

    if (!activePersona) {
        return <></>;
    }

    return (
        <div className="activity-container h-full">
            <div className="brain-activity-arin">
                <div className="brain-activity-header flex items-center justify-between">
                    <div className="leading flex gap-1 items-center p-1 bg-white">
                        <LordIcon
                            src="/lord-icons/brain.json"
                            trigger="hover"
                            colors={{
                                primary: "#121331",
                                secondary: "#333"
                            }}
                            stroke={40}
                            size={24}
                        />
                        <span className="title">Brain Scan</span>
                        <span>
                            <BiChevronUp />
                        </span>
                    </div>
                    <div className="trailing chakra-styled flex p-1 bg-white gap-2">
                        <Button colorScheme={displayMode === "cells" ? "blue" : "gray"} onClick={toggleCellsDisplayMode} variant="outline">
                            cells
                        </Button>
                        <Button onClick={toggleChainDisplayMode} colorScheme={displayMode === "chain" ? "blue" : "gray"} variant="outline">
                            chain
                        </Button>
                    </div>
                </div>
                <div className="activity-inner">
                    <div className="persona-select arin border-b border-gray-100">
                        <PersonaNameDropMenu hasImageLoaded={hasImageLoaded} setHasImageLoaded={setHasImageLoaded} persona={activePersona} />
                        <div className="actions flex items-center justify-center pt-2">
                            <button onClick={() => setIsMenuOpen(!isMenuOpen)} onBlur={() => setIsMenuOpen(false)} className={personaList?.length > 1 ? "" : "hidden"}>
                                <LordIcon
                                    src="/lord-icons/chevron-right.json"
                                    trigger="hover"
                                    colors={{
                                        primary: "#121331",
                                        secondary: "#333"
                                    }}
                                    stroke={40}
                                    size={16}
                                />
                                {isMenuOpen && (
                                    <div className="dropdown-menu">
                                        {personaList?.map((persona) => (
                                            <div
                                                key={persona.id}
                                                onClick={() => {
                                                    onChangePersona(persona.id!);
                                                    setIsMenuOpen(false);
                                                }}
                                            >
                                                {persona.first_name + " " + persona.last_name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>

                    <div className="body overflow-y-auto pt-2">
                        {/* <ThoughtsMessage
                            title="Searching Memory"
                            description="Understanding the question. I have generated three similar questions that may help me in querying my memories. I have identified that there are similarities with the information that I can provide immediately, but let’s go through a longer retrieval. I am matching over the malleable parts of my brain, and find some cool stuff. Found somethings in the structured parts as well. I am not sure how I feel about this, but I am formulating my response now. Sent to James."
                        /> */}

                        {/* <ThoughtsMessage title="Perusing" description="I’m reading the information now, some cool things in there, how can I help James now? Asking the question.  " /> */}

                        {/* <ThoughtsMessage
                            title="Searching Memory"
                            description="This is a complex problem and task. I am finding which part of my skillset I should choose now. Ok, I’ve identified the right one. Initiating the retriever actions now."
                        /> */}

                        {displayMode === "all" &&
                            activeThoughtMessageList?.map((thoughtMessage, index) => <ThoughtsMessage key={index} title={thoughtMessage.title} description={thoughtMessage.body} />)}

                        {displayMode !== "cells" && <ChainSequence chainList={activeChainList} />}

                        {displayMode !== "chain" && activeSequenceList?.map((sequence, index) => <BrainScanSequence key={index} sequence={sequence} />)}

                        {/* <BrainScanSequence sequence={{
                            persona_id:
                            collaboration_app_id: "1234",
                            message_list: [{
                                observation: `I went through this entire thing and that was
                                absolutely mental and horrible. You cannot expect me
                                to do his work for him. But alas, those humans are
                                really rude and ridiculous as it stands.`,
                                message: `I went through this entire thing and that was
                                absolutely mental and horrible. You cannot expect me
                                to do his work for him. But alas, those humans are
                                really rude and ridiculous as it stands.`
                            }, {
                                plan: `I went through this entire thing and that was
                                absolutely mental and horrible. You cannot expect me
                                to do his work for him. But alas, those humans are
                                really rude and ridiculous as it stands.`,
                                message: `I went through this entire thing and that was
                                absolutely mental and horrible. You cannot expect me
                                to do his work for him. But alas, those humans are
                                really rude and ridiculous as it stands.`
                            }]
                        }} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface PersonaDropMenuProps {
    hasImageLoaded: boolean;
    setHasImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    persona: PersonaModel;
}

function PersonaNameDropMenu ({ hasImageLoaded, setHasImageLoaded, persona }: PersonaDropMenuProps) {
    return (
        <div className="flex items-center gap-2">
            <div className="arin-image">
                <img src={persona.image || "/user_avatar.png"} className={`${hasImageLoaded ? "block" : "hidden"}`} onLoad={() => setHasImageLoaded(true)} alt="" />
                {!hasImageLoaded && <SkeletonCircle size="30" />}
            </div>
            <div className="profile">
                <p className="arin-name">{persona.first_name + " " + persona.last_name}</p>
                <p className="arin-username">
                    {persona.username ? `@${persona.username} ` : ""}
                    {persona.type}
                </p>
            </div>
        </div>
    );
}

function ThoughtsMessage ({ title, description }: { title?: string; description: string }) {
    return (
        <div className="thoughts-container">
            <div className="thoughts-wrapper">
                <div className="thoughts-header">{title}</div>
                <div className="thoughts-decription">
                    {description.split(/\n+/).map((item, index, array) => (
                        <div key={index}>
                            <p>{item}</p>
                            {index < array.length - 1 && <br />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

// function ThoughtSequence () {
//     return (
//         <div className="thoughts-sequence-container">
//             <div className="thoughts-wrapper">
//                 <div className="thoughts-header flex justify-between">
//                     <div className="leading flex items-center gap-1">
//                         <button className="header-tag">sequence 93842</button>
//                         <button className="header-tag">interrupt</button>
//                     </div>
//                     <div className="trailing">
//                         <button className="header-tag">forget</button>
//                     </div>
//                 </div>
//                 <div className="cell">
//                     <h2 className="cell-title">Cell 1</h2>
//                     <div className="cell-item">
//                         <div className="cell-item-head flex items-center gap-1">
//                             <div className="icon-box">
//                                 <AiOutlineEye className="icon" size="18px" />
//                             </div>
//                             <h1 className="cell-item-title">observation</h1>
//                         </div>
//                         <p className="cell-item-description">
//                             I went through this entire thing and that was absolutely mental and horrible. You cannot expect me to do his work for him. But alas, those humans are really rude and
//                             ridiculous as it stands.
//                         </p>
//                     </div>

//                     <div className="cell-item">
//                         <div className="cell-item-head flex items-center gap-1">
//                             <div className="icon-box">
//                                 <AiOutlineCalendar className="icon" size="18px" />
//                             </div>
//                             <h1 className="cell-item-title">planning</h1>
//                         </div>
//                         <p className="cell-item-description">
//                             I went through this entire thing and that was absolutely mental and horrible. You cannot expect me to do his work for him. But alas, those humans are really rude and
//                             ridiculous as it stands.
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

function ChainSequence ({ chainList }: { chainList?: ChainItem[] }) {
    const dispatch = useDispatch();
    const activePersonaId = useSelector(selectActivePersonaId);

    const removeMessageFromChain = (answerId: string) => {
        dispatch(
            removeChainItem({
                personaId: activePersonaId,
                answerId
            })
        );
    };

    const clearChainMessages = () => {
        dispatch(clearChain({ persona_id: activePersonaId }));
    };

    if (!Array.isArray(chainList) || chainList.length === 0) {
        return <></>;
    }

    return (
        <div className="chain-sequence-container">
            <div className="chain-wrapper">
                <div className="chain-header flex justify-between">
                    <div className="leading flex items-center gap-1">
                        <button className="header-tag">memory chain</button>
                    </div>
                    <div className="trailing">
                        <button className="header-tag" onClick={clearChainMessages}>
                            forget
                        </button>
                    </div>
                </div>
                <div className="chain-proper">
                    <div className="cell-body pt-[4px] pb-[20px] px-[20px]">
                        {chainList.map((chainItem, index) => (
                            <div key={index} className="cell-item">
                                <div className="cell-item-head flex items-center gap-1">
                                    <div className="icon-box">
                                        <div className="chain-dot ml-[1px] h-[15px] w-[15px] rounded-full bg-gray-950"></div>
                                    </div>
                                    <h1 className="cell-item-title truncate" title={chainItem.questionContent}>
                                        {chainItem.questionContent}
                                    </h1>
                                    <button className="unchain-btn hidden text-[14px] text-red-600" onClick={() => removeMessageFromChain(chainItem.answerMessageId)}>
                                        unchain
                                    </button>
                                </div>
                                <p className="cell-item-description">{chainItem.answerContent}</p>
                            </div>
                        ))}

                        {/* <div className="cell-item">
                            <div className="cell-item-head flex items-center gap-1">
                                <div className="icon-box">
                                    <div className="chain-dot ml-[1px] h-[15px] w-[15px] rounded-full bg-gray-950"></div>
                                </div>
                                <h1 className="cell-item-title">did you know?</h1>
                                <button className="unchain-btn hidden text-[14px] text-red-600">unchain</button>
                            </div>
                            <p className="cell-item-description">I went through this entire thing and that was absolutely mental and horrible. You cannot expect me to do…</p>
                        </div>

                        <div className="cell-item">
                            <div className="cell-item-head flex items-center gap-1">
                                <div className="icon-box">
                                    <div className="chain-dot ml-[1px] h-[15px] w-[15px] rounded-full bg-gray-950"></div>
                                </div>
                                <h1 className="cell-item-title">Whatever this is, it’s not good?</h1>
                                <button className="unchain-btn hidden text-[14px] text-red-600">unchain</button>
                            </div>
                            <p className="cell-item-description">I went through this entire thing and that was absolutely mental and horrible. You cannot expect me to do…</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
