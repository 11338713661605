import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PersonaModel } from "../../models/persona.model";
import { type RoomGroupModel } from "../../models/room.model";

export interface PersonaState {
    loading: boolean;
    personas: PersonaModel[];
    personaPortalId: string;
    personaPortals: RoomGroupModel[];
    personaPortalPage: number;
}

const initialState: PersonaState = {
    loading: false,
    personas: [],
    personaPortalId: "",
    personaPortals: [],
    personaPortalPage: 1
};

const personaSlice = createSlice({
    name: "persona",
    initialState,
    reducers: {
        setPersonaLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        populatePersonas: (state, action: PayloadAction<PersonaModel[]>) => {
            // Remove personas with empty names
            // const personas = action.payload.filter(
            //     (persona: PersonaModel) => !!persona.name,
            // );
            state.personas = [...action.payload];
        },
        addPersona: (state, action: PayloadAction<PersonaModel>) => {
            state.personas.push(action.payload);
        },
        updatePersona: (state, action: PayloadAction<PersonaModel>) => {
            const index = state.personas.findIndex((persona) => persona.id === action.payload.id);
            if (index > -1) {
                state.personas[index] = action.payload;
            }
        },
        removePersona: (state, action: PayloadAction<string>) => {
            const index = state.personas.findIndex((persona) => persona.id === action.payload);
            if (index > -1) {
                state.personas.splice(index, 1);
            }
        },
        setPersonaPortals: (state, action: PayloadAction<RoomGroupModel[]>) => {
            state.personaPortals = action.payload;
        },
        addPersonaPortals: (state, action: PayloadAction<RoomGroupModel[]>) => {
            state.personaPortals = [...state.personaPortals, ...action.payload];
        },
        setPersonaPortalId: (state, action: PayloadAction<string>) => {
            state.personaPortalId = action.payload;
        },
        setPersonaPortalsPage(state, action: PayloadAction<number>) {
            state.personaPortalPage = action.payload;
        }
    }
});

// Export actions
export const {
    setPersonaLoading,
    populatePersonas,
    addPersona,
    updatePersona,
    removePersona,
    setPersonaPortals,
    setPersonaPortalId,
    addPersonaPortals,
    setPersonaPortalsPage
} = personaSlice.actions;

export default personaSlice.reducer;
