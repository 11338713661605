import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import WaitingRoom from "../views/WaitingRoom";
import RoomsCanvasView from "../views/RoomsCanvasView";
import ThreadView from "../views/ThreadView";
// import OAuthCallback from "../views/auth/OAuthCallback";
import RoomView from "../views/RoomView";
import SubscriptionFailed from "../views/payments/callback/SuscriptionFailed";
import SubscriptionSuccess from "../views/payments/callback/SubscriptionSuccess";
import PaywallView from "../views/payments/PaywallView";
import GenealogyTree from "../views/GenealogyTree";
import OpenPersonaPortalView from "../views/OpenPersonaPortalView";
import PersonaRoomView from "../views/PersonaRoomView";
import KeycloakWrapper from "../views/keycloak-wrapper";

export enum ROUTES {
    ROOT = "/",
    // OAUTH_CALLBACK = "/oauth_callback",
    WAITING_ROOM = "/waiting-room/:id",
    THREAD_VIEW = "/waiting-room/thread/:id",
    ROOM_CHAT = "/room/:id",
    PERSONA_ROOM_CHAT = "/room/:id/persona/:personaId",
    ROOM_GROUP_CHAT = "/room-group/:id",
    ROOM_CHAT_THREAD = "/room/:id/thread/:messageId",
    ROOM_GROUP_THREAD = "/room-group/:id/thread/:messageId",
    SUBSCRIPTION_SUCCESS_CALLBACK = "/payments/callback/subscription-success",
    SUBSCRIPTION_CANCELLED_CALLBACK = "/payments/callback/subscription-cancelled",
    CREDIT_PURCHASE_CALLBACK = "/payments/callback/credit-purchase",
    PAYWALL = "/payments/paywall",
    OPEN_PERSONA_PORTAL = "/open-portal",
    GENEALOGYTREE = "/genealogy"
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: ROUTES.ROOT,
                element: (
                    <KeycloakWrapper>
                        <RoomsCanvasView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.WAITING_ROOM,
                element: (
                    <KeycloakWrapper>
                        <WaitingRoom />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.ROOM_CHAT_THREAD,
                element: (
                    <KeycloakWrapper>
                        <ThreadView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.ROOM_GROUP_THREAD,
                element: (
                    <KeycloakWrapper>
                        <ThreadView />
                    </KeycloakWrapper>
                )
            },
            // {
            //     path: ROUTES.OAUTH_CALLBACK,
            //     element: (
            //         <OAuthCallback />
            //     )
            // },
            {
                path: ROUTES.ROOM_CHAT,
                element: (
                    <KeycloakWrapper>
                        <RoomView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.PERSONA_ROOM_CHAT,
                element: (
                    <KeycloakWrapper>
                        <PersonaRoomView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.ROOM_GROUP_CHAT,
                element: (
                    <KeycloakWrapper>
                        <RoomView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.GENEALOGYTREE,
                element: (
                    <KeycloakWrapper>
                        <GenealogyTree />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.SUBSCRIPTION_SUCCESS_CALLBACK,
                element: <SubscriptionSuccess />
            },
            {
                path: ROUTES.SUBSCRIPTION_CANCELLED_CALLBACK,
                element: <SubscriptionFailed />
            },
            {
                path: ROUTES.PAYWALL,
                element: (
                    <KeycloakWrapper>
                        <PaywallView />
                    </KeycloakWrapper>
                )
            },
            {
                path: ROUTES.OPEN_PERSONA_PORTAL,
                element: (
                    <KeycloakWrapper>
                        <OpenPersonaPortalView />
                    </KeycloakWrapper>
                )
            }
        ]
    }
]);

export default router;
