import { type PersonaModel } from "../models/persona.model";

export function getPersonaFullName (persona: PersonaModel, personaString?: string): string {
    if (persona?.first_name) {
        return `${persona.first_name} ${persona.last_name}`;
    } else {
        return personaString || "";
    }
}

export function getPersonaProfilePicture (persona: PersonaModel): string {
    if (persona?.profile_picture_full_2D) {
        const [filename, extension] = persona.profile_picture_full_2D.split(".");
        return `${process.env.REACT_APP_API_URL}/api/v1/persona/show-profile-picture/${filename}/${extension}`;
    } else {
        return "/user_avatar.png";
    }
}
