import Keycloak from "keycloak-js";

enum KeycloakClientState {
    INITIALIZED = "initialized",
    UNINITIALIZED = "uninitialized",
    INITIALIZING = "initializing"
}

export default class KeycloakClient {
    private static _instance: KeycloakClient | null = null;
    private readonly _keycloak: Keycloak;
    private _initialized_state = KeycloakClientState.UNINITIALIZED;
    private _authInfo: any = null;

    private constructor() {
        const keycloakConfig = {
            realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
            url: process.env.REACT_APP_KEYCLOAK_URL || "",
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ""
        };

        this._keycloak = new Keycloak({
            realm: keycloakConfig.realm,
            url: keycloakConfig.url,
            clientId: keycloakConfig.clientId
        });
    }

    async init() {
        if (this._initialized_state === KeycloakClientState.UNINITIALIZED) {
            try {
                this._initialized_state = KeycloakClientState.INITIALIZING;
                await this._keycloak.init({
                    onLoad: "login-required"
                });

                this._initialized_state = KeycloakClientState.INITIALIZED;
            } catch (error) {
                this._initialized_state = KeycloakClientState.UNINITIALIZED;
            }
        }
    }

    static getInstance() {
        if (!this._instance) {
            this._instance = new KeycloakClient();
        }

        return this._instance;
    }

    login() {
        this._keycloak.login({
            // the redirect url should be the path to /auth/login
            redirectUri: window.location.origin + "/auth/login"
        });
    }

    get isAuthenticated() {
        return this._keycloak.authenticated;
    }

    get userInfo() {
        return this._authInfo;
    }

    get token() {
        return this._keycloak.token;
    }

    async getAuthProfile() {
        return (this._authInfo =
            this._authInfo || (await this._keycloak.loadUserProfile()));
    }

    async getAuthUserToken() {
        try {
            await this._keycloak.updateToken();
        } catch (error) {
            console.error("Failed to refresh the token:", error);
        }

        return this._keycloak.token ?? "";
    }

    async logout() {
        this._keycloak.logout();
    }

    addSuccessCallback(callback: () => void) {
        const originalOnAuthSuccess = this._keycloak.onAuthSuccess;

        this._keycloak.onAuthSuccess = () => {
            if (typeof originalOnAuthSuccess === "function") {
                originalOnAuthSuccess();
            }
            callback();
        };
    }
}
