import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type SearchModel } from "../../models/search.model";
import { type CharacterModel } from "../../models/character.model";
import { useSelector } from "react-redux";
import { type PromptModel } from "../../models/prompt.model";

interface SearchesSlice {
    isSearchLoading: boolean;
    isCharacterLoading: boolean;
    history: string;
    prompts: PromptModel[];
    chain: SearchModel[]; // Responses that have been added to chain
    folderChain: SearchModel[]; // Responses that have been added to chain
    folderHistory: string;
    character: CharacterModel;
    replies: SearchModel[];
    characterLoadingError: boolean;
}

const initialState: SearchesSlice = {
    isSearchLoading: false,
    isCharacterLoading: true,
    characterLoadingError: false,
    history: "",
    chain: [],
    folderChain: [],
    folderHistory: "",
    prompts: [],
    character: {
        character: "",
        character_message: ""
    },
    replies: []
};

const searchesSlice = createSlice({
    name: "searches",
    initialState,
    reducers: {
        setCharacter: (state, action: PayloadAction<CharacterModel>) => {
            state.character = action.payload;
        },
        setSearchLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isSearchLoading = action.payload;
        },
        setCharacterLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isCharacterLoading = action.payload;
        },
        setCharacterLoadingError: (state, action: PayloadAction<boolean>) => {
            state.characterLoadingError = action.payload;
        },
        addSearchQuery: (state, action) => {
            state.replies = [...state.replies, action.payload];
        },
        updateLastQuery: (state, action) => {
            state.replies = [...state.replies].map((response, index) => {
                if (
                    index === state.replies.length - 1 &&
                    response.question === action.payload.question
                ) {
                    console.log({ ...response, ...action.payload });
                    return { ...response, ...action.payload };
                }
                return response;
            });
        },
        removeLastQuery: (state) => {
            state.replies = state.replies.slice(0, -1);
        },
        updateSearchQuery: (state, action) => {
            state.replies = [...state.replies].map((response) => {
                if (response.id === action.payload.id) {
                    return action.payload;
                }
                return response;
            });
        },
        // Chains
        addQueryToChain: (state, action: PayloadAction<SearchModel>) => {
            // If query exist in chain, exit
            const index = state.chain.findIndex(
                (item) => item.id === action.payload.id
            );
            if (index < 0) {
                state.chain = [...state.chain, action.payload];
            }
        },
        setChain: (state, action: PayloadAction<SearchModel[]>) => {
            state.chain = action.payload;
        },
        removeFromChain: (state, action: PayloadAction<number>) => {
            state.chain = state.chain.filter(
                (item) => item.id !== action.payload
            );
        },
        clearChain: (state) => {
            state.chain = [];
        },
        // Folder chain
        addQueryToFolderChain: (state, action: PayloadAction<SearchModel>) => {
            // If query exist in chain, exit
            const index = state.folderChain.findIndex(
                (item) => item.id === action.payload.id
            );
            if (index < 0) {
                state.folderChain = [...state.folderChain, action.payload];
            }
        },
        setFolderChain: (state, action: PayloadAction<SearchModel[]>) => {
            state.folderChain = action.payload;
        },
        removeFromFolderChain: (state, action: PayloadAction<number>) => {
            state.folderChain = state.folderChain.filter(
                (item) => item.id !== action.payload
            );
        },
        clearFolderChain: (state) => {
            state.folderChain = [];
        },
        // --- end
        setHistory: (state, action: PayloadAction<string>) => {
            state.history = action.payload;
        },
        clearHistory: (state) => {
            state.history = "";
        },
        // folder chain history
        setFolderHistory: (state, action: PayloadAction<string>) => {
            state.folderHistory = action.payload;
        },
        clearFolderHistory: (state) => {
            state.folderHistory = "";
        },
        setPrompts: (state, action: PayloadAction<PromptModel[]>) => {
            state.prompts = action.payload;
        }
    }
});

// Export actions
export const {
    addSearchQuery,
    updateSearchQuery,
    setCharacterLoadingStatus,
    setSearchLoadingStatus,
    setCharacterLoadingError,
    setCharacter,
    removeLastQuery,
    updateLastQuery,
    removeFromChain,
    addQueryToChain,
    setChain,
    addQueryToFolderChain,
    setFolderChain,
    removeFromFolderChain,
    clearFolderChain,
    setFolderHistory,
    clearFolderHistory,
    setPrompts,
    clearChain,
    setHistory,
    clearHistory
} = searchesSlice.actions;

export const useIsChained = (id: number) =>
    useSelector(
        (state: any) => !!state.chain.find((item: any) => item.id === id)
    );

export const getChainFromState = () => useSelector((state: any) => state.chain);

export const useHistoryFromState = () =>
    useSelector((state: any) => state.history);

export default searchesSlice.reducer;
