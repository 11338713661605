import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function formatDate (date: string | number | null, format = "") {
    // @ts-expect-error: Ignoring type checking for utc parameter as it is added by the plugin.
    const localTime = date ? dayjs.utc(date).local() : dayjs.utc().local();
    if (format) {
        return localTime.format(format);
    }
    return localTime.format("DD/MM/YYYY");
}

export function newUTCDate () {
    // @ts-expect-error: Ignoring type checking for utc parameter as it is added by the plugin.
    return dayjs().utc().format();
}
