import React, { useCallback, useRef } from "react";
import type { PersonaModel } from "../models/persona.model";
import { getPersonaFullName } from "../utils/strings.utils";
import { Button } from "@chakra-ui/button";
import { getPersonaProfilePicture } from "../utils/personas.utils";
import Draggable from "react-draggable";
import {
    Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader, ModalOverlay,
    Spinner,
    useDisclosure
} from "@chakra-ui/react";
import { useHttpClient } from "../utils/http.utils";
import { type PersonaState } from "../store/slices/persona.slice";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/date.utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import { debounce } from "lodash";

interface PersonaCardProps {
    persona: PersonaModel;
}

const PersonaCard: React.FC<PersonaCardProps> = ({ persona }) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const portalsDisclosure = useDisclosure();
    const { listPersonaPortalsApi } = useHttpClient();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const personaState: PersonaState = useSelector((state: any) => state.personas);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [dropInPortalId, setDropInPortalId] = React.useState<string>("");
    const [isLoadingPersonaRooms, setIsLoadingPersonaRooms] = React.useState<boolean>(false);
    const [isLoadingMoreRooms, setIsLoadingMoreRooms] = React.useState<boolean>(false);

    /**
    * Debounce search
    */
    const debouncedSearch = useCallback(debounce((value: string) => {
        const searchQuery: any = { personaId: persona.id! };

        if (value) {
            searchQuery.userEmail = value.trim().toLowerCase();
        }

        setIsLoadingPersonaRooms(true);
        listPersonaPortalsApi(searchQuery, { limit: 10 })
            .finally(() => setIsLoadingPersonaRooms(false));
    }, 500), []);

    const openModal = async () => {
        portalsDisclosure.onOpen();
        // load portals data if not already loaded
        // if (persona.id !== personaState.personaPortalId) {

        // }
        setIsLoading(true);
        await listPersonaPortalsApi({ personaId: persona.id! });
        setIsLoading(false);
    };

    const clearSearch = () => {
        /**
         *  If there is a search query, clear it and refresh the list
         *  This is done because when a search is performed, the list is filtered and only the search results are in the state,
         *  So re-opening the modal or dropping into a room will only show the filtered results when the modal is opened again,
         *  This can be perceived as a bug from the user perspective
         */
        console.log(!!searchInputRef.current?.value);
        if (searchInputRef.current?.value) {
            searchInputRef.current.value = "";
            listPersonaPortalsApi({ personaId: persona.id! });
        }
    };

    const closeFormModal = () => {
        portalsDisclosure.onClose();
        clearSearch();
    };

    const dropInPortal = (id: string) => {
        // This is to show the loading because the navigation can be delayed by the fetch function
        setDropInPortalId(id);
        navigate(ROUTES.PERSONA_ROOM_CHAT
            .replaceAll(":id", id)
            .replaceAll(":personaId", persona.id!)
        );
        clearSearch();
    };

    const loadMorePortals = async () => {
        setIsLoadingMoreRooms(true);
        listPersonaPortalsApi({}, { limit: 10, page: personaState.personaPortalPage + 1 });
        setIsLoadingMoreRooms(false);
    };

    return (
        <>
            <Draggable
                handle=".drag-persona-handler"
                nodeRef={containerRef}
            // onStart={console.log}
            // onDrag={console.log}
            // onStop={console.log}
            >
                <div ref={containerRef}>
                    <div className="relative drag-persona-handler bg-white border rounded-[12px] w-[250px] h-[300px] overflow-hidden cursor-pointer shadow-md hover:border-blue-500 border-neutral-100 ">
                        <img src={getPersonaProfilePicture(persona)} alt="" className="h-[60%] w-full object-cover" />
                        <div className="bg-white absolute bottom-0 left-0 right-0 min-h-[100px] p-4 rounded-t-lg">
                            <h2 className="persona-name">{getPersonaFullName(persona)}</h2>
                            <h4 className="m-0 text-neutral-500 mb-4">{persona.email}</h4>
                            <Button
                                className="w-full bg-neutral-50"
                                size={"sm"}
                                variant={"ghost"}
                                onClick={openModal}
                            >
                                See portals
                            </Button>
                        </div>
                    </div>
                </div>

            </Draggable>

            <Modal
                isOpen={portalsDisclosure.isOpen}
                onClose={closeFormModal} isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <span className="text-[18px] font-[400]">{getPersonaFullName(persona)}&apos;s Portals</span>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="flex flex-col h-[400px] overflow-hidden relative">
                            <div className="pb-2 min-h-[40px]">
                                <div className="input-group relative">
                                    <Input
                                        placeholder="Search.."
                                        size={"sm"}
                                        onInput={(e) => debouncedSearch((e.target as any).value as string)}
                                    />
                                    {isLoadingPersonaRooms && <p className="absolute right-2 top-1/2 -translate-y-1/2">
                                        <Spinner size="sm" />
                                    </p>}
                                </div>
                            </div>
                            <div className="flex-grow overflow-y-auto">
                                {
                                    isLoading
                                        ? (<div className="h-full w-full flex flex-col justify-center items-center">
                                            <Spinner />
                                            <p className="mt-2 text-slate-500">Loading portals..</p>
                                        </div>)
                                        : (
                                            <div className="h-full overflow-y-auto px-2">
                                                {
                                                    personaState.personaPortals.map((portal) => {
                                                        const memberList = portal.user_member_list.map((member) => (member.user?.first_name + " " + member.user?.last_name) + " (" + member.user?.email + ")").join(", ");
                                                        return (
                                                            <div key={portal.id} className="border-b flex justify-between py-2 mb-2">
                                                                <div className="max-w-[80%]">
                                                                    <h3 className="m-0 text-[15px] font-[300px]" title={memberList}>
                                                                        {memberList}
                                                                    </h3>
                                                                    {/* <p className="m-0 text-neutral-500 truncate">
                                                                        with&nbsp;
                                                                        <span className="text-blue-500 mr-1" title={memberList}>{memberList}</span>
                                                                    </p> */}
                                                                    <p className="m-0 text-neutral-500 truncate">
                                                                        {portal.description || `Created on ${formatDate(portal.createdAt!)}`}
                                                                    </p>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <Button
                                                                        variant={"link"}
                                                                        size={"sm"}
                                                                        className="text-blue-500"
                                                                        isLoading={dropInPortalId === portal.id}
                                                                        loadingText=""
                                                                        onClick={() => dropInPortal(portal.id)}
                                                                    >
                                                                        Drop In
                                                                    </Button>

                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                {
                                                    personaState.personaPortals.length === 0 &&
                                                    !isLoading &&
                                                    <div className="h-[300px] w-full flex justify-center items-center">
                                                        <p className="text-neutral-500">No portals found</p>
                                                    </div>
                                                }

                                            </div>
                                        )
                                }
                            </div>
                            <div className="load-more flex justify-center w-full absolute bottom-0 left-0 right-0 py-2">
                                <Button onClick={loadMorePortals} isLoading={isLoadingMoreRooms} variant="ghost" size="sm">
                                    <span className="text-blue-500">Load more</span>
                                </Button>
                            </div>
                        </div>
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={closeFormModal}>
                            Close
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    );
};

export default PersonaCard;
